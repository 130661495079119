import { deleteBlockWithSocket } from '../grid/reduxStore/gridBlockSocketActions';
import { deleteGridBlockState } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { AcknowledgmentResponseStatus } from '../../../services/socket/SocketEvents';
import { openNotification } from '../../notification';
import { useTranslation } from 'react-i18next';

type GridBlockDeleteHandler = (gridBlockId: string) => void;

export default function useGridBlockDeleteHandler() {
  const dispatch = useAppDispatch();
  const { data } = useCurrentUser();
  const userId = data.id;
  const socketClient = useSocketClient();
  const { t } = useTranslation();

  const gridBlockDeleteHandler: GridBlockDeleteHandler = (gridBlockId) => {
    dispatch(
      deleteBlockWithSocket({
        gridBlockId,
        socketClient,
        userId,
        callback: (response) => {
          const responseParsed = JSON.parse(response);
          if (responseParsed.status !== AcknowledgmentResponseStatus.OK) {
            openNotification({
              title: t('document.grid.delete.error'),
              description: '',
              type: 'error',
            });
          }
          const payload = {
            blockId: gridBlockId,
          };
          dispatch(deleteGridBlockState(payload));
        },
      })
    );
  };

  return { gridBlockDeleteHandler };
}
