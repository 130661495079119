import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Document } from 'services/documents/entities/Document';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';
import { QueryKeys } from 'utils/queryKeys';

type Params = {
  filterTrash?: boolean;
  filterTerm?: string;
  filterStatus?: string;
};

export function useDocumentListQuery({ filterTrash = false, filterTerm = '', filterStatus = '' }: Params) {
  const queryClient = useQueryClient();
  const queryKey = QueryKeys.getDocumentList(filterTrash, filterTerm, filterStatus);

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => apiDocumentRepository.list({ filterTrash, filterTerm, filterStatus }),
  });

  const mutation = useMutation({
    mutationFn: async (newList: Document[]) => {
      queryClient.setQueryData(queryKey, { documents: newList });
    },
  });

  const setDocumentList = async (param: Document[] | ((prev: Document[]) => Document[])) => {
    if (typeof param === 'function') {
      const documentListFromQuery = queryClient.getQueryData<{ documents: Document[] }>(queryKey)?.documents ?? [];
      await mutation.mutateAsync(param(documentListFromQuery));
    } else {
      await mutation.mutateAsync(param);
    }
  };

  return {
    isLoadingDocumentList: query.isFetching,
    documentList: query.data?.documents || [],
    setDocumentList,
    refreshDocumentList: query.refetch,
  };
}
