import { useState, useContext, useCallback, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import './style.less';
import '../../../styles/tokens.less';
import { SignaturesContext, SignatureSocketWriteOperationPayload } from '../providers/SignaturesProvider';
import { GridTextBlock } from './GridTextBlock';
import { GridImageLoader } from './GridImageLoader';
import { Block } from './models/Block.model';
import { BlockConfig } from './models/BlockConfig.model';
import { GridBlockType } from '../shared/gridBlockType';
import {
  gridDefaultHeightOnDrop,
  gridDefaultWidthOnDrop,
  gridPageMaxWidthInPixels,
  gridPageMinHeightInPixels,
  gridPixelSize,
} from '../shared/gridConfig';
import { SignatureStatus } from 'services/repositories/interfaces/SignatureRepository';
import SignedSignature from './SignatureBlocks/SignedSignatureBlock';
import UnSignedSignature from './SignatureBlocks/UnsignedSignatureBlock';
import DragLines from './DragLines/DragLines';
import { EditorConfig } from '../helpers/config';
import { RootState, rootStore } from '../grid/reduxStore/store';
import { useSelector } from 'react-redux';
import { TrackedBlockCollection } from './models/TrackedBlockCollection.model';
import { calculatePageMaxHeight, setupTrackedBlocks } from './gridHelper';
import Placeholder from './Placeholder/Placeholder';
import { openNotification } from '../../notification';
import { useTranslation } from 'react-i18next';
import { GridImageBlock } from './GridImageBlock';

export type GridBlockAddHandler = (documentContent: string, blockConfig: Block, type: string) => void;
export type GridBlockChangeHandler = (gridBlockId: string, documentContent: string) => void;
export type GridBlockPositionChangeHandler = (gridBlockId: string, x: number, y: number) => void;
export type GridBlockDimensionChangeHandler = (gridBlockId: string, width: number, height: number) => void;

export type GridDndEditorProps = {
  documentId: string;
  configOptions?: EditorConfig;
  gridRef: React.MutableRefObject<HTMLDivElement | null>;
  gridBlockAddHandler?: GridBlockAddHandler;
  gridBlockChangeHandler: GridBlockChangeHandler;
  gridBlockPositionChangeHandler?: GridBlockPositionChangeHandler;
  gridBlockDimensionChangeHandler?: GridBlockDimensionChangeHandler;
};

export default function GridDndEditor({
  documentId,
  configOptions,
  gridRef,
  gridBlockAddHandler,
  gridBlockChangeHandler,
  gridBlockPositionChangeHandler,
  gridBlockDimensionChangeHandler,
}: GridDndEditorProps) {
  const blocksContent = rootStore.getState().gridBlockReducer.blocksContent;
  // blocksMetadata necessary as a pair to blocksContent, otherwise an error will occur.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const blocksMetadata = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksMetadata;
  });
  const { handleSignatureInsert, signatures, setSelectedSignature } = useContext(SignaturesContext);
  const [currentDraggedBlock, setCurrentDraggedBlock] = useState<BlockConfig | null>(null);
  const [maxPageHeight, setMaxPageHeight] = useState<number>(calculatePageMaxHeight(blocksContent, signatures));
  const [trackedBlocks, setTrackedBlocks] = useState<TrackedBlockCollection>(setupTrackedBlocks(blocksContent, signatures));
  const { t } = useTranslation();

  const updateBlocks = (item: BlockConfig) => {
    const tempBlocks = {
      ...trackedBlocks,
      [item.id]: {
        height: item.height,
        width: item.width as number,
        x: item.x,
        y: item.y,
      },
    };
    delete tempBlocks['placeholder'];
    setTrackedBlocks({
      ...tempBlocks,
    });
    setCurrentDraggedBlock(null);
  };

  const [, drop] = useDrop(() => ({
    accept: [GridBlockType.TEXT, GridBlockType.SIGNATURE],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item, monitor) => {
      const offset = monitor.getClientOffset();
      if (offset && gridRef.current) {
        const boundingBox = gridRef.current.getBoundingClientRect();
        const x = offset.x - boundingBox.x;
        const y = offset.y - boundingBox.y;
        const itemType = monitor.getItemType();

        if (itemType === GridBlockType.TEXT) {
          if (gridBlockAddHandler) {
            gridBlockAddHandler(
              '',
              {
                x: x,
                y: y,
                z: rootStore.getState().gridBlockReducer.blocksLayer.greaterZIndexAvailable,
                width: gridDefaultWidthOnDrop,
                height: gridDefaultHeightOnDrop,
              },
              'add'
            );
          }
        } else if (itemType === GridBlockType.SIGNATURE && item) {
          const signatureItem = item as SignatureSocketWriteOperationPayload;
          const payload: SignatureSocketWriteOperationPayload = {
            ...signatureItem,
            properties: {
              dimensions: signatureItem.properties.dimensions,
              position: {
                x: x,
                y: y,
              },
            },
          };
          handleSignatureInsert(payload);
        }
      }
    },
  }));

  function combinedRef(el) {
    drop(el);
    if (el) {
      gridRef.current = el;
    }
  }

  const editorResizeHandler = (editorId: string, textbox: DOMRectReadOnly) => {
    const resizedBlock = blocksContent[editorId].blockConfig;
    const currentBlock = { ...resizedBlock };
    const isBaseOnTextBox = (resizedBlock.height && textbox.height > resizedBlock.height) || !resizedBlock.height;
    if (gridBlockDimensionChangeHandler && isBaseOnTextBox) {
      currentBlock.height = textbox.height;
      gridBlockDimensionChangeHandler(editorId, resizedBlock.width || 0, textbox.height);
      setDocumentHeight(currentBlock);
    }
  };

  const isBlockDroppedOffCanvas = (data, item: BlockConfig) => {
    const { x, y } = data;

    const isMovedOffRightSide = x >= gridPageMaxWidthInPixels;
    const isMovedOffLeftSide = x + item.width <= 0;
    const isMovedOffTopSide = y + item.height <= 0;

    return isMovedOffRightSide || isMovedOffLeftSide || isMovedOffTopSide;
  };

  const handleStop = (_, data, item: BlockConfig) => {
    updateBlocks(item);
    if (isBlockDroppedOffCanvas(data, item)) {
      openNotification({
        title: t('editor.blocks.placing.outside_canvas_warn.title'),
        description: t('editor.blocks.placing.outside_canvas_warn.desc'),
        type: 'warning',
      });

      const computedPageMaxHeightFromAllBlocks = calculatePageMaxHeight(blocksContent, signatures);
      setMaxPageHeight(computedPageMaxHeightFromAllBlocks);
      return;
    }
    const { x, y } = data;

    // Round the x and y positions to the nearest multiple of gridSize
    const roundedX = Math.round(x / gridPixelSize) * gridPixelSize;
    const roundedY = Math.round(y / gridPixelSize) * gridPixelSize;

    if (gridBlockPositionChangeHandler) {
      gridBlockPositionChangeHandler(item.id, roundedX, roundedY);
    }
  };

  const handleSignatureStop = (_, item: BlockConfig) => {
    updateBlocks(item);
  };

  const onResizeStop = (e, direction, ref, delta, item: BlockConfig) => {
    const { width, height } = ref.style;
    const parsedWidth = parseInt(width, 10);
    const parsedHeight = parseInt(height, 10);
    if (gridBlockDimensionChangeHandler) {
      gridBlockDimensionChangeHandler(item.id, parsedWidth, parsedHeight);
    }
    setDocumentHeight(item);
  };

  const handleDrag = (_, draggedBlock: BlockConfig) => {
    setCurrentDraggedBlock(draggedBlock);
    setDocumentHeight(draggedBlock);
  };

  const handleDragStart = (_, data) => {
    setTrackedBlocks({
      ...trackedBlocks,
      placeholder: {
        height: data.height,
        width: data.width,
        x: data.x,
        y: data.y,
      },
    });
  };

  const setDocumentHeight = useCallback(
    (currentBlock?) => {
      const computedPageMaxHeightFromAllBlocks = calculatePageMaxHeight(blocksContent, signatures, currentBlock);
      setMaxPageHeight(computedPageMaxHeightFromAllBlocks);
    },
    [blocksContent, signatures]
  );

  // update document height when image/text block is drop to canvas
  useEffect(() => {
    setDocumentHeight();
  }, [blocksMetadata]);

  const maxHeight = Math.max(maxPageHeight, gridPageMinHeightInPixels);

  return (
    <div className="editor__page" ref={combinedRef} style={{ height: maxHeight }}>
      <DragLines
        currentBlock={currentDraggedBlock}
        trackedBlockCollection={trackedBlocks}
        maxWidthPx={gridPageMaxWidthInPixels}
        maxHeightPx={maxHeight}
      />
      <GridImageLoader documentId={documentId} />
      <div className="editor__page__container">
        {trackedBlocks['placeholder'] && (
          <Placeholder
            position={{ x: trackedBlocks['placeholder'].x, y: trackedBlocks['placeholder'].y }}
            dimension={{ width: trackedBlocks['placeholder'].width, height: trackedBlocks['placeholder'].height }}
          />
        )}
        <GridImageBlock
          gridBlocks={blocksContent}
          handleDrag={handleDrag}
          handleStop={handleStop}
          onResizeStop={onResizeStop}
          handleDragStart={handleDragStart}
          gridPixelSize={gridPixelSize}
        />
        <GridTextBlock
          documentId={documentId}
          configOptions={configOptions}
          blockContentChangeHandler={gridBlockChangeHandler}
          editorResizeHandler={editorResizeHandler}
          handleDrag={handleDrag}
          handleStop={handleStop}
          onResizeStop={onResizeStop}
          handleDragStart={handleDragStart}
          gridPixelSize={gridPixelSize}
        />
        {signatures?.map(({ signatureBoxId, properties, status, signatureEvent, assignedSignee }) => {
          return status === SignatureStatus.Signed ? (
            <SignedSignature
              signee={signatureEvent}
              position={properties.position}
              dimensions={properties.dimensions}
              signatureId={signatureBoxId}
              key={signatureBoxId}
              signedDate={signatureEvent.signedDate}
              bounds=".editor__page"
              onDrag={handleDrag}
              onSignatureStop={handleSignatureStop}
              onDragStart={handleDragStart}
            />
          ) : (
            <UnSignedSignature
              assignedSignee={assignedSignee}
              position={properties.position}
              dimensions={properties.dimensions}
              signatureId={signatureBoxId}
              key={signatureBoxId}
              handleClick={() => setSelectedSignature(signatureBoxId)}
              bounds=".editor__page"
              onDrag={handleDrag}
              onSignatureStop={handleSignatureStop}
              onDragStart={handleDragStart}
            />
          );
        })}
      </div>
    </div>
  );
}
