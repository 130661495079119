import { Icon } from 'components';
import { useDocumentListQuery } from 'hooks/useDocumentListQuery';
import { PipelineList } from '../../list';
import { useTranslation } from 'react-i18next';
import { moveDocumentToTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { restoreDocumentFromTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Snackbar } from 'components/snackbar';
import { Button, Input } from 'antd';
import './styles.less';
import { SearchEmptyResult } from './search-empty-result';
import { trackEvents } from 'utils/tracking';
import { EmptyState } from '../../empty-state';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Check } from '@mui/icons-material';

const TERM_QUERY_PARAM = 'term';
const STATUS_QUERY_PARAM = 'status';

type HandleQueryParamsProps = {
  filterTerm?: string;
  filterStatus?: string;
};

export function ListDocuments() {
  const { t } = useTranslation();

  const pagePathname = window.location.pathname;
  const newQueryParameters = new URLSearchParams();
  const [currentQueryParams, setQueryParams] = useSearchParams();
  const currentSearchTerm = decodeURIComponent(currentQueryParams.get(TERM_QUERY_PARAM) ?? '');
  const currentStatusFilter = decodeURIComponent(currentQueryParams.get(STATUS_QUERY_PARAM) ?? '');

  const { documentList, isLoadingDocumentList, setDocumentList, refreshDocumentList } = useDocumentListQuery({
    filterTrash: false,
    filterTerm: currentSearchTerm,
    filterStatus: currentStatusFilter,
  });

  const documentStatuses = ['Draft', 'Approval', 'Approved', 'Sent', 'Won', 'Lost', 'Unsigned'];
  const [selectedItemStatus, setSelectedItemStatus] = useState(['All']);

  useEffect(() => {
    return () => {
      const isRedirectingToAnotherPage = window.location.pathname !== pagePathname;
      if (isRedirectingToAnotherPage) {
        return;
      }
      newQueryParameters.delete(TERM_QUERY_PARAM);
      setQueryParams(newQueryParameters);
    };
  }, []);

  const handleSearch = (value: string) => {
    if (value === currentSearchTerm) {
      return;
    }
    handleQueryParams({ filterTerm: value });
  };

  const handleStatusFilter = (value: string) => {
    if (value === currentStatusFilter) {
      return;
    }
    handleQueryParams({ filterStatus: value });
  };

  const handleQueryParams = ({ filterTerm = currentSearchTerm, filterStatus = currentStatusFilter }: HandleQueryParamsProps) => {
    if (filterTerm === '') {
      newQueryParameters.delete(TERM_QUERY_PARAM);
    } else {
      newQueryParameters.append(TERM_QUERY_PARAM, encodeURIComponent(filterTerm));
      trackEvents('documents_search', { searchTerm: filterTerm });
    }

    if (filterStatus === '') {
      newQueryParameters.delete(STATUS_QUERY_PARAM);
    } else {
      newQueryParameters.append(STATUS_QUERY_PARAM, encodeURIComponent(filterStatus));
    }

    setQueryParams(newQueryParameters);
  };

  const handleMoveToTrashAction = async (documentId: string) => {
    await moveDocumentToTrash(documentId);
  };

  const handleMoveToTrashUndo = async (documentId: string) => {
    await restoreDocumentFromTrash(documentId);
  };

  const handleDuplicateActionUndo = async () => {
    await refreshDocumentList();
  };

  const handleStatusButtonOnChangeClick = (event, selectedStatus: string[]) => {
    const clickedStatus = event.target.value;

    if (clickedStatus === 'All') {
      setSelectedItemStatus(['All']);
      handleStatusFilter('');
    } else if (selectedItemStatus.length === 1 && currentStatusFilter === clickedStatus) {
      setSelectedItemStatus(['All']);
      handleStatusFilter('');
    } else {
      const filterSelectedStatus = selectedStatus.filter((status) => status !== 'All');
      setSelectedItemStatus(filterSelectedStatus);
      handleStatusFilter(filterSelectedStatus.join(','));
    }
  };

  return (
    <>
      {documentList.length !== 0 && (
        <div className="documents-list-search-bar-area">
          <Input.Search
            defaultValue={currentSearchTerm}
            addonBefore={<Button className="documents-list-search-button" icon={<Icon name="IcoSearch24Px" />} />}
            placeholder={t('pipeline.tabs.search.inputPlaceholder')}
            className="documents-list-search-bar"
            bordered={false}
            allowClear={{ clearIcon: <Button icon={<Icon name="IcoCloseX" />} /> }}
            onBlur={(event) => handleSearch(event.target.value)}
            onSearch={(event) => handleSearch(event)}
            data-testid="pipelineSearchInput"
          ></Input.Search>
        </div>
      )}
      {currentSearchTerm && (
        <Snackbar message={t('pipeline.tabs.search.searchResults', { count: documentList.length, term: currentSearchTerm })} />
      )}

      <PipelineList
        type="Documents"
        documentList={documentList}
        setDocumentList={setDocumentList}
        isLoading={isLoadingDocumentList}
        listEmpty={currentSearchTerm ? <SearchEmptyResult /> : <EmptyState />}
        action={{
          message: t('pipeline.tabs.document_list.action_message'),
          tooltipText: t('pipeline.tabs.document_list.action_tooltip'),
          onAction: handleMoveToTrashAction,
          onUndo: handleMoveToTrashUndo,
          onDuplicate: handleDuplicateActionUndo,
          icon: <Icon name="IcoTrash" />,
        }}
      />
      <ToggleButtonGroup
        className="status-filter-bar"
        value={selectedItemStatus}
        onChange={handleStatusButtonOnChangeClick}
        data-testid="status-filter-bar"
      >
        <ToggleButton className="status-button" value="All" selected={selectedItemStatus.includes('All')}>
          {selectedItemStatus.includes('All') && <Check fontSize="small" />}
          All
        </ToggleButton>
        {documentStatuses.map((status) => (
          <ToggleButton className="status-button" key={status} value={status} selected={selectedItemStatus.includes(status)}>
            {selectedItemStatus.includes(status) && <Check fontSize="small" />}
            {status}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}
