import FroalaTextbox from '../grid/FroalaTextbox';
import React from 'react';
import { DraggableGridBlockWrapper } from './DraggableGridBlockWrapper';
import { BlockConfig } from './models/BlockConfig.model';
import { GridBlockType } from '../shared/gridBlockType';
import { EditorConfig } from '../helpers/config';
import { useSelector } from 'react-redux';
import { rootStore, RootState } from '../grid/reduxStore/store';

interface GridTextBlockProps {
  documentId: string;
  configOptions?: EditorConfig;
  gridPixelSize: number;
  editorResizeHandler: (editorId: string, rect: DOMRectReadOnly) => void;
  blockContentChangeHandler: (gridBlockId: string, blockContent: string) => void;
  handleDrag: (e, block: BlockConfig) => void;
  handleStop: (e, data, item: BlockConfig) => void;
  onResizeStop: (e, direction, ref, delta, item: BlockConfig) => void;
  onDragStop?: (e, data, item: BlockConfig) => void;
  handleDragStart: (e, data) => void;
}
export const GridTextBlock: React.FC<GridTextBlockProps> = ({
  documentId,
  configOptions,
  gridPixelSize,
  editorResizeHandler,
  blockContentChangeHandler,
  handleDrag,
  handleStop,
  onResizeStop,
  handleDragStart,
}) => {
  const blocksContent = rootStore.getState().gridBlockReducer.blocksContent;
  const blocksMetadata = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksMetadata;
  });

  const handleOnResizeStop = (e, direction, ref, delta, item: BlockConfig) => {
    const editorId = `froala_editor_${item.id}`;
    const inputBox = document.querySelector('.' + editorId);

    if (!inputBox) return;
    const canResizeTheBlock =
      inputBox.getBoundingClientRect().height < item.height || inputBox.getBoundingClientRect().width !== item.width;
    if (canResizeTheBlock) {
      onResizeStop(e, direction, ref, delta, item);
    }
  };

  return (
    <>
      {blocksMetadata
        .filter((block) => block.type === GridBlockType.TEXT)
        .map((block) => (
          <DraggableGridBlockWrapper
            key={`grid-text-${block.id}`}
            blockId={block.id}
            gridSystemInPx={gridPixelSize}
            handleStop={handleStop}
            handleDrag={handleDrag}
            handleResizeStop={handleOnResizeStop}
            handleDragStart={handleDragStart}
          >
            <FroalaTextbox
              documentId={documentId}
              key={block.id}
              gridBlockId={block.id}
              configOptions={configOptions}
              documentContent={blocksContent[block.id].content}
              editorResizeHandler={editorResizeHandler}
              contentChangeHandler={blockContentChangeHandler}
            />
          </DraggableGridBlockWrapper>
        ))}
    </>
  );
};

GridTextBlock.displayName = 'GridTextBlock';
