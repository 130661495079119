import React, { useCallback, useState, useEffect } from 'react';
import { AcknowledgmentResponseStatus } from 'services/socket/SocketEvents';
import { DocumentSaveStatus } from '../helpers/DocumentSaveStatus';
import { SignatureBox, UnSignedSignatureBox, SignatureStatus } from 'services/repositories/interfaces/SignatureRepository';
import UnSignedSignature from 'components/editor/GridDndEditor/SignatureBlocks/UnsignedSignatureBlock';
import SignedSignature from 'components/editor/GridDndEditor/SignatureBlocks/SignedSignatureBlock';

export type SignatureSocketWriteOperationPayload = UnSignedSignatureBox & {
  documentId: string;
};

type HandleSignatureSocketWriteOperation = (params: SignatureSocketWriteOperationPayload) => void;
interface SignatureContextType {
  documentId: string;
  signatures?: SignatureBox[];
  handleSignatureInsert: HandleSignatureSocketWriteOperation;
  handleSignatureRemoval: (signatureId: string) => void;
  handleSignaturePropertyUpdate: HandleSignatureSocketWriteOperation;
  setSignaturesOnMount: (signatures: SignatureBox[]) => void;
  signatureSaveStatus: SaveStatus;
  setSelectedSignature: (signatureId: string) => void;
  getSignaturesList: (isGridEnabled: boolean) => React.ReactNode[];
}

export interface SaveStatus {
  status: DocumentSaveStatus;
  errCode?: string;
}

export const SignaturesContext = React.createContext<SignatureContextType>({} as SignatureContextType);

interface SignaturesProviderProps {
  documentId: string;
  children: React.ReactNode;
  socketClient: any; // TODO: Properly type the signature socket client;
}

export const SignaturesProvider = ({ documentId, children, socketClient }: SignaturesProviderProps) => {
  const [signatures, setSignatures] = useState<SignatureBox[]>([]);
  const [signatureSaveStatus, setSignatureSaveStatus] = useState<SaveStatus>({ status: DocumentSaveStatus.SAVED, errCode: '' });
  const [selectedSignature, setSelectedSignature] = useState<string>('');

  const signatureActionAck = (response: string) => {
    const { status, error } = JSON.parse(response);
    if (status === AcknowledgmentResponseStatus.OK) {
      setSignatureSaveStatus({ status: DocumentSaveStatus.SAVED, errCode: '' });
    } else {
      setSignatureSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: error });
    }
  };

  const handleSignatureInsert = useCallback(
    (signature: UnSignedSignatureBox) => {
      setSignatureSaveStatus({ status: DocumentSaveStatus.SAVING, errCode: '' });
      socketClient.addSignatureContent({ ...signature, id: signature.signatureBoxId }, (response) => {
        signatureActionAck(response);

        setSignatures((prevSignatures) => {
          const { content = {} } = JSON.parse(response);

          if (content) signature.signatureBoxId = content.id;

          return [...prevSignatures, { ...signature }];
        });
      });
    },
    [signatures]
  );

  const handleSignatureRemoval = useCallback(
    (signatureId: string) => {
      setSignatures((prevSignatures) => {
        return prevSignatures.filter((signature) => {
          if (signature.signatureBoxId === signatureId) {
            setSignatureSaveStatus({ status: DocumentSaveStatus.SAVING, errCode: '' });
            socketClient.deleteSignatureContent(signature, (response) => signatureActionAck(response));
          }
          return signature.signatureBoxId !== signatureId;
        });
      });
    },
    [signatures]
  );

  const handleSignaturePropertyUpdate = useCallback(
    (newSignature: UnSignedSignatureBox) => {
      socketClient.updateSignatureContent(newSignature, (response) => signatureActionAck(response));
      setSignatures((prevSignatures) => {
        prevSignatures.map((signature) => {
          if (signature.signatureBoxId === newSignature.signatureBoxId) {
            setSignatureSaveStatus({ status: DocumentSaveStatus.SAVING, errCode: '' });
            Object.assign(signature, newSignature);
          }
        });
        return prevSignatures;
      });
    },
    [signatures]
  );

  const setSignaturesOnMount = useCallback((data: SignatureBox[]) => {
    setSignatures(data);
  }, []);

  const handleMouseDown = useCallback(() => {
    setSelectedSignature('');
  }, []);

  const handleHotKeys = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      if (key === 'Delete' || key === 'Backspace') {
        handleSignatureRemoval(selectedSignature);
      }
    },
    [handleSignatureRemoval, selectedSignature]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleHotKeys);
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('keydown', handleHotKeys);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown, handleHotKeys]);

  const getSignaturesList = useCallback(
    (isGridEnabled: boolean) => {
      const bounds = isGridEnabled ? '.editor__page' : '.fr-box.fr-basic';
      return (signatures ?? []).map(({ signatureBoxId, properties, ...rest }) => {
        return rest.status === SignatureStatus.Signed ? (
          <SignedSignature
            signee={rest.signatureEvent}
            position={properties.position}
            dimensions={properties.dimensions}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            signedDate={rest.signatureEvent.signedDate}
            bounds={bounds}
          />
        ) : (
          <UnSignedSignature
            assignedSignee={rest.assignedSignee}
            position={properties.position}
            dimensions={properties.dimensions}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            handleClick={() => setSelectedSignature(signatureBoxId)}
            bounds={bounds}
          />
        );
      });
    },
    [signatures]
  );

  return (
    <SignaturesContext.Provider
      value={{
        documentId,
        signatures,
        handleSignatureInsert,
        handleSignatureRemoval,
        handleSignaturePropertyUpdate,
        setSignaturesOnMount,
        signatureSaveStatus,
        setSelectedSignature,
        getSignaturesList,
      }}
    >
      {children}
    </SignaturesContext.Provider>
  );
};
