import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import './styles.less';

import { SocketClientInterface } from '../../services/socket/SocketClient';
import { AcknowledgmentResponseStatus } from '../../services/socket/SocketEvents';
import { EditorConfig, editorConfig } from './helpers/config';
import { DocumentSaveStatus } from './helpers/DocumentSaveStatus';
import { SaveStatus } from './providers/SignaturesProvider';

interface EditorProps {
  configOptions?: EditorConfig;
  documentId: string;
  socketClient: SocketClientInterface;
  handleDocumentContentStatus: React.Dispatch<React.SetStateAction<SaveStatus>>;
  handleUsedVariablesParsing: (documentContent: string) => void;
  initialDocContent: string | undefined;
}

export const Editor = forwardRef<FroalaEditor, EditorProps>(
  ({ configOptions = {}, initialDocContent, socketClient, handleDocumentContentStatus, handleUsedVariablesParsing }, ref) => {
    const [documentContent, setDocumentContent] = useState<string | undefined>(initialDocContent);
    const defaultConfig = {
      ...editorConfig,
      ...configOptions,
    };

    useEffect(() => {
      socketClient.onContentGet((initialDataFromServer: string) => {
        setDocumentContent(initialDataFromServer);
      });

      socketClient.onContentChanged((latestDataFromServer: string) => {
        setDocumentContent(latestDataFromServer);
      });
    }, [socketClient.getConnectionStatus()]);

    const handleChange = useCallback(
      (newDocumentContent: string) => {
        handleDocumentContentStatus({ status: DocumentSaveStatus.SAVING, errCode: '' });
        setDocumentContent(newDocumentContent);
        socketClient.pubContentChanged(newDocumentContent, function (response: string) {
          const { status, error } = JSON.parse(response);
          if (status === AcknowledgmentResponseStatus.OK) {
            handleDocumentContentStatus({ status: DocumentSaveStatus.SAVED, errCode: '' });
          } else {
            handleDocumentContentStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: error });
          }
        });

        handleUsedVariablesParsing(newDocumentContent);
      },
      [documentContent]
    );
    return <FroalaEditor ref={ref} tag="textarea" model={documentContent} onModelChange={handleChange} config={defaultConfig} />;
  }
);

Editor.displayName = 'Editor';
