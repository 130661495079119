import IcoTrash from '../../icon/icons/IcoTrash';
import useGridBlockDeleteHandler from './useGridBlockDeleteHandler';
import GridDeleteWarningModal from './models/GridDeleteWarningModal';
import { useState } from 'react';
import './style.less';

export interface GridDeleteComponentProp {
  gridBlockId: string;
}
export default function GridDeleteComponent({ gridBlockId }: GridDeleteComponentProp) {
  const { gridBlockDeleteHandler } = useGridBlockDeleteHandler();
  const [shouldModalVisible, setIsModalVisible] = useState(false);

  const handleDeleteOk = () => {
    gridBlockDeleteHandler(gridBlockId);
    setIsModalVisible(false);
  };

  return (
    <>
      <IcoTrash className="editor__page__draggable__grid-delete-handle" onClick={() => setIsModalVisible(true)} />
      <GridDeleteWarningModal
        isDeleteRequested={shouldModalVisible}
        handleOk={() => handleDeleteOk()}
        handleCancel={() => setIsModalVisible(false)}
      />
    </>
  );
}
