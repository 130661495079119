import { io, Socket, SocketOptions, ManagerOptions } from 'socket.io-client';
import SignatureSocketClient from './SignatureSocketClient';
import { Events } from './SocketEvents';
import { SignatureBox, SignatureBoxProperties, UnSignedSignatureBox } from 'services/repositories/interfaces/SignatureRepository';
import { SignatureBoxApiResponse } from 'services/repositories/interfaces/SignatureRepository';
import { SignatureSocketWriteOperationPayload } from 'components/editor/providers/SignaturesProvider';

import { transformSignatureBoxApiResponse } from 'services/repositories/implementations/ApiSignatureRepository';
import GridSocketClient from './GridSocketClient';
import { GridAddType, GridDeleteType, GridLayerChangedType } from '../../components/editor/grid/reduxStore/saveReducers';

type AuthenticationObject = {
  [key: string]: any;
};

export interface SocketClientInterface {
  connect(serverURL: string, authParams: AuthenticationObject): void;
  disconnect(): void;
  onContentGet(TSocketCallback: TSocketCallback): void;
  onContentChanged(TSocketCallback: TSocketCallback): void;
  pubContentChanged(data: string, callback?: (args: any) => any): void | string;
  getConnectionStatus(): boolean | undefined;
  getAllSignaturesOnMount(callback: GetAllSignaturesOnMountCallback): void;
  addGrid(data: GridAddType, callback: TSocketCallback): void;
  deleteGrid(data: GridDeleteType, callback: TSocketCallback): void;
  gridContentChanged(data: any, callback: TSocketCallback): void;
  updatePositionContent(data: any, callback: TSocketCallback): void;
  updateLayer(data: GridLayerChangedType, callback: TSocketCallback): void;
  updateDimensionContent(data: any, callback: TSocketCallback): void;
  getGridInitialContentLoad(callback: TSocketCallback): void;
}

type SignatureBoxSocketResponse = Omit<SignatureBoxApiResponse, 'properties'> & { properties: string };
type GetAllSignaturesOnMountCallback = (data: SignatureBox[]) => void;

export type TSocketCallback = (data: any) => void;

export function defaultCallback() {
  return;
}

const transformSignatureSocketResponseToApiResponse = ({ properties: propertiesString, ...rest }: SignatureBoxSocketResponse) => {
  const properties: SignatureBoxProperties = typeof propertiesString === 'string' ? JSON.parse(propertiesString) : propertiesString;
  const transformed = {
    properties,
    ...rest,
  } as SignatureBoxApiResponse;
  return transformed;
};

class SocketClient implements SocketClientInterface {
  constructor() {
    this.#signatureSocketClient = new SignatureSocketClient();
    this.#gridSocketClient = new GridSocketClient();
  }

  #signatureSocketClient: SignatureSocketClient;
  #gridSocketClient: GridSocketClient;

  #socketClient?: Socket;

  #connectionParams: Partial<ManagerOptions & SocketOptions> = {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 5,
    auth: {},
    transports: ['websocket'],
  };

  public getConnectionStatus() {
    return this.#socketClient?.connected;
  }

  public connect(serverURL: string, authParams: AuthenticationObject): void {
    this.#connectionParams.auth = authParams;
    this.#socketClient = io(serverURL, this.#connectionParams);
    this.#socketClient.connect();
  }

  public disconnect() {
    if (this.#socketClient) {
      this.#socketClient.disconnect();
    }
  }

  public onContentGet(callback: TSocketCallback) {
    this.subscribeOnce(Events.CONTENT_GET, (data: string) => {
      callback(data);
    });
  }

  public getAllSignaturesOnMount(callback: GetAllSignaturesOnMountCallback) {
    this.subscribeOnce(Events.SIGNATURE_GET_ALL, (response: SignatureBoxSocketResponse[] = []) => {
      const data = response.map((signature) => {
        const res = transformSignatureSocketResponseToApiResponse(signature);
        return transformSignatureBoxApiResponse(res);
      });
      callback(data);
    });
  }

  public onContentChanged(callback: TSocketCallback) {
    this.subscribe(Events.CONTENT_CHANGED, (data: string) => {
      callback(data);
    });
  }

  public pubContentChanged(data: string, callback: (args: any) => any) {
    this.publish(Events.CONTENT_CHANGED, data, callback);
  }

  public subscribe(event: Events, callback: any): SocketClient {
    if (this.#socketClient) {
      this.#socketClient.on(event, callback);
    }
    return this;
  }

  private subscribeOnce(event: Events, callback: any): SocketClient {
    if (this.#socketClient) {
      this.#socketClient.once(event, callback);
    }
    return this;
  }

  private publish(event: Events, data: any, callback: (resp: string) => any): SocketClient {
    if (this.#socketClient) {
      this.#socketClient.emit(event, data, callback);
    }
    return this;
  }

  public onSignaturesChanged(callback: TSocketCallback) {
    this.#signatureSocketClient.handleSignatureUpdate.call(this, callback);
  }

  public addSignatureContent(data: SignatureSocketWriteOperationPayload, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent.call(this, data, Events.SIGNATURE_ADD, callback);
  }

  public updateSignatureContent(data: Array<UnSignedSignatureBox & { documentId: string }>, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent.call(this, data, Events.SIGNATURE_UPDATE, callback);
  }

  public deleteSignatureContent(data: UnSignedSignatureBox[], callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent.call(this, data, Events.SIGNATURE_DELETE, callback);
  }

  public addGrid(data: GridAddType, callback: TSocketCallback) {
    this.#gridSocketClient.publishGridAdded.call(this, data, Events.GRID_ADD, callback);
  }

  public deleteGrid(data: GridDeleteType, callback: TSocketCallback): void {
    this.#gridSocketClient.publishGridDeleted.call(this, data, Events.GRID_DELETE, callback);
  }

  public gridContentChanged(data: any, callback: TSocketCallback) {
    this.#gridSocketClient.publishGridContentChanged.call(this, data, Events.GRID_CONTENT_CHANGED, callback);
  }

  public updatePositionContent(data: any, callback: TSocketCallback) {
    this.#gridSocketClient.publishGridPositionChanged.call(this, data, Events.GRID_POSITION_CHANGED, callback);
  }
  public updateLayer(data: GridLayerChangedType, callback: TSocketCallback) {
    this.#gridSocketClient.publishGridLayerChanged.call(this, data, Events.GRID_LAYER_CHANGED, callback);
  }

  public updateDimensionContent(data: any, callback: TSocketCallback) {
    this.#gridSocketClient.publishGridDimensionChanged.call(this, data, Events.GRID_DIMENSION_CHANGED, callback);
  }

  public getGridInitialContentLoad(callback: TSocketCallback) {
    this.#gridSocketClient.getGridInitialContentLoad.call(this, '', Events.GRID_INITIAL_LOAD, callback);
  }
}

export default SocketClient;
