import React, { useEffect, useState } from 'react';
import './style.less';
import { openNotification } from '../../notification';
import useImageSave from '../../../hooks/useImageSave';
import { calculateImageWidthAndHeight } from '../GridDndEditor/gridHelper';
import { Block } from '../GridDndEditor/models/Block.model';
import { getImageStatusError } from 'components/editor/helpers/getImageStatusError';
import { useTranslation } from 'react-i18next';
import { DocumentImageLibraryPayload, UploadDocumentImageResponse } from 'services/repositories/interfaces/DocumentRepository';
import { useDocumentImageUploadMutation } from 'hooks/useDocumentImageMutation';
import { ImageUploadErrorResponse } from '../shared/models/ImageUploadErrorResponse';
import { PromiseAllSettledResult, PromiseAllSettledStatus } from 'interfaces/PromisedAllSettledResult';
import { CheckImageExtension } from '../helpers/CheckImageExtension';
import { rootStore } from '../grid/reduxStore/store';

interface ImageDropProps {
  documentId: string;
  setLoadingComponents?: any;
}

interface DroppedPosition {
  offset: number;
  top: number;
}

const IMAGE_DROP_BACKGROUND_CLASS = 'file-drop-background';

export const ImageDrop: React.FC<ImageDropProps> = ({ documentId, setLoadingComponents }) => {
  const [isCursorInsideEditor, setIsCursorInsideEditor] = useState<boolean>(false);
  const { uploadImage } = useDocumentImageUploadMutation();
  const { imageSave } = useImageSave();
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  const { t } = useTranslation();

  const getDroppedPosition = (event): DroppedPosition | undefined => {
    const dropTarget = document.querySelector(`.${IMAGE_DROP_BACKGROUND_CLASS}`);

    if (!dropTarget) {
      return;
    }

    const rect = dropTarget.getBoundingClientRect();

    const offset = event.clientX - rect.left;
    const top = event.clientY - rect.top;

    return { offset, top };
  };

  const handleDroppedUrl = (droppedUrl, event) => {
    const position = getDroppedPosition(event);

    if (!position) return;
    const { offset, top } = position;
    const img = new Image();
    img.onload = function () {
      const actualWidth = img.width;
      const actualHeight = img.height;
      const { calculatedWidth, calculatedHeight } = calculateImageWidthAndHeight(actualWidth, actualHeight);

      imageSave(documentId, droppedUrl, {
        x: offset,
        y: top,
        z: rootStore.getState().gridBlockReducer.blocksLayer.greaterZIndexAvailable,
        width: calculatedWidth,
        height: calculatedHeight,
      });
    };
    img.src = droppedUrl;
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const droppedUrlKey = 'text/uri-list';
    const droppedUrl = event.dataTransfer.getData(droppedUrlKey);
    if (droppedUrl) {
      handleDroppedUrl(droppedUrl, event);
    } else {
      const files = event.dataTransfer.files as FileList;

      if (!files?.length) {
        return;
      }

      for (const file of Array.from(files)) {
        if (!CheckImageExtension(file)) {
          openNotification({
            title: t('document.image.errors.not_supported_types_title'),
            description: t('document.image.errors.not_supported_types_desc'),
            type: 'error',
          });

          return;
        }
      }

      uploadImages(Array.from(files), event);
    }
  };

  const uploadImages = async (files: File[], event) => {
    setIsLoadingImage(true);
    const imageData: any = [];

    for (const file of Array.from(files)) {
      const fileAsDataURL = window.URL.createObjectURL(file);
      const { height, width } = await getHeightAndWidthFromDataUrl(fileAsDataURL);
      const position = getDroppedPosition(event);

      if (!position) {
        return;
      }

      const { offset, top } = position;
      const { calculatedWidth, calculatedHeight } = calculateImageWidthAndHeight(width, height);

      const skeletonComponent: Block = {
        x: offset,
        y: top,
        z: rootStore.getState().gridBlockReducer.blocksLayer.greaterZIndexAvailable,
        width: calculatedWidth,
        height: calculatedHeight,
      };

      setLoadingComponents((prev: Block[]) => [...prev, skeletonComponent]);

      imageData.push({
        fileName: file.name,
        position: { x: offset, y: top },
        size: { width: calculatedWidth, height: calculatedHeight },
      });
    }

    const payload: DocumentImageLibraryPayload = {
      documentId,
      images: files,
    };

    uploadImage(payload, {
      onSuccess: async (result: PromiseAllSettledResult<UploadDocumentImageResponse>[]) => {
        const hasSuccess = result.some((r) => r.status === PromiseAllSettledStatus.Fulfilled);
        const errors = result.filter((r) => r.status === PromiseAllSettledStatus.Rejected);

        if (errors.length && hasSuccess) {
          openNotification({
            type: 'warning',
            title: t('document.image.errors.mixed_success_title'),
            description: t('document.image.errors.mixed_success_desc'),
          });
        } else if (hasSuccess) {
          openNotification({
            type: 'success',
            title: t('document.image.success'),
            description: '',
          });
        } else {
          if (errors.length === 1) {
            const { title, desc } = getImageStatusError(errors?.[0]?.reason?.status_code, t);

            openNotification({
              title,
              description: desc,
              type: 'error',
            });
          } else {
            const errorMessages = new Set<string>();

            errors.forEach((e) => {
              const { desc } = getImageStatusError(e?.reason?.status_code, t);
              errorMessages.add(desc);
            });

            openNotification({
              type: 'error',
              title: t('document.image.errors.multiple_errors'),
              description: Array.from(errorMessages).join(', '),
            });
          }
        }

        if (hasSuccess) {
          const images = result.filter((r) => r.status === PromiseAllSettledStatus.Fulfilled);
          images.forEach((image, index) => {
            if (image?.value) {
              const imageDetails = imageData.find((data) => data.fileName === image.value?.filename);

              if (imageDetails) {
                const imageStagger = 30; // bumps each image a bit (in px) so they don't overlap

                imageSave(documentId, image.value.link, {
                  x: imageDetails.position.x + index * imageStagger,
                  y: imageDetails.position.y + index * imageStagger,
                  z: rootStore.getState().gridBlockReducer.blocksLayer.greaterZIndexAvailable,
                  width: imageDetails.size.width,
                  height: imageDetails.size.height,
                });
              }
            }
          });
        }

        setIsLoadingImage(false);
      },
      onError: (error) => {
        if (!error) {
          return;
        }

        const e = error as ImageUploadErrorResponse;
        const { title, desc } = getImageStatusError(e.status_code, t);

        openNotification({
          title,
          description: desc,
          type: 'error',
        });

        setIsLoadingImage(false);
      },
    });
  };

  const getHeightAndWidthFromDataUrl = (dataURL: string): Promise<{ height: number; width: number }> =>
    new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };

      img.src = dataURL;
    });

  useEffect(() => {
    const images = document.querySelectorAll('img');
    images.forEach((image) => {
      image.addEventListener('dragover', (event) => event.preventDefault());
      image.addEventListener('drop', handleDrop);
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('dragover', (event) => event.preventDefault());
        image.removeEventListener('drop', handleDrop);
      });
    };
  }, [documentId, handleDrop]);

  useEffect(() => {
    if (!isLoadingImage) {
      setLoadingComponents([]);
    }
  }, [isLoadingImage]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsCursorInsideEditor(event.target.classList.contains('froala-editor-wrapper'));
  };

  return (
    <div
      onDrop={isCursorInsideEditor ? undefined : handleDrop}
      onDragOver={handleDragOver}
      className={IMAGE_DROP_BACKGROUND_CLASS}
      style={{ visibility: isCursorInsideEditor ? 'hidden' : 'visible' }}
    ></div>
  );
};
