import React, { useCallback, useState } from 'react';
import { Input } from 'antd';
import { Modal } from 'components';
import UnSignedSignature from '../GridDndEditor/SignatureBlocks/UnsignedSignatureBlock';
import './styles.less';
import { useSignSignatureMutation } from 'hooks/useSignSignatureMutation';
import { openNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { AssignedSignee, SignatureBox, SignatureRepository } from 'services/repositories/interfaces/SignatureRepository';
import { ApiSignatureRepository } from 'services/repositories/implementations/ApiSignatureRepository';
import { Dimensions, Position } from '../shared/interfaces';
import { Form } from 'components/form';
import { useMediaQuery } from 'hooks/useMediaQuery';

type WithSignatureModalProps = {
  signatureId: string;
  position: Position;
  dimensions: Dimensions;
  documentId: string;
  assignedSignee: AssignedSignee | null;
  setSignatures: React.Dispatch<React.SetStateAction<SignatureBox[]>>;
};

interface FormValue {
  firstName: string;
  lastName: string;
  email: string;
  initials: string;
}

const signatureRepository: SignatureRepository = new ApiSignatureRepository();

const extractAssignedSigneeInitials = ({ firstName, lastName }): string => {
  let initials = '';

  if (firstName) {
    initials += firstName.charAt(0);
  }

  if (lastName.includes('-')) {
    const accountForHyphenatedLastNames = lastName.split('-');
    for (const lastNames of accountForHyphenatedLastNames) {
      initials += lastNames.charAt(0);
    }
  } else if (lastName) {
    initials += lastName.charAt(0);
  }

  return initials;
};

const WithSignatureModal: React.FC<WithSignatureModalProps> = ({
  signatureId,
  position,
  dimensions,
  documentId,
  assignedSignee,
  setSignatures,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormValue>({
    firstName: assignedSignee?.firstName ?? '',
    lastName: assignedSignee?.lastName ?? '',
    email: assignedSignee?.email ?? '',
    initials: assignedSignee ? extractAssignedSigneeInitials(assignedSignee) : '',
  });
  const { mutate: signSignature } = useSignSignatureMutation(signatureRepository);
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const [form] = Form.useForm<FormValue>();

  const validateFormBeforeSubmit = () => {
    form.validateFields().then(() => saveSignature());
  };

  const saveSignature = useCallback(() => {
    signSignature(
      {
        documentId: documentId,
        signatureBoxId: signatureId,
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        initials: formState.initials,
      },
      {
        onError: () => {
          openNotification({
            type: 'error',
            title: t('editor.signature.sign_failure_message'),
            description: '',
          });
        },
        onSuccess: async (signedSignature: SignatureBox) => {
          setSignatures((prev) => {
            return prev.map((signature) => {
              return signature.signatureBoxId === signedSignature.signatureBoxId ? signedSignature : signature;
            });
          });
        },
        onSettled: () => {
          setIsOpen(false);
        },
      }
    );
  }, [formState.firstName, formState.lastName, formState.email, formState.initials]);

  const { firstName, lastName, initials } = formState;

  return (
    <>
      <UnSignedSignature
        assignedSignee={assignedSignee}
        position={position}
        dimensions={dimensions}
        signatureId={signatureId}
        key={signatureId}
        handleClick={() => setIsOpen(true)}
        bounds="fr-element"
        enableResizing={false}
        disableDragging={true}
      />
      <Modal
        isVisible={isOpen}
        handleCancel={() => setIsOpen(false)}
        handleOk={validateFormBeforeSubmit}
        affirmText="Sign & Accept"
        cancelText="Cancel"
        className="signature-modal"
        width={isMobile ? '100%' : 600}
        title="Add your signature"
      >
        <Form
          onValuesChange={(value) => {
            setFormState((prev) => {
              return { ...prev, ...value };
            });
          }}
          layout="vertical"
          className="signature-modal-form"
          initialValues={formState}
          form={form}
        >
          <div className="form-input-info-wrapper">
            <Form.Item
              label="First Name"
              name="firstName"
              className="form-input-first-name"
              rules={[{ required: true, whitespace: true, message: 'Please enter a valid name' }]}
            >
              <Input placeholder="First Name" maxLength={256} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              required
              className="form-input-last-name"
              name="lastName"
              rules={[{ required: true, whitespace: true, message: 'Please enter a valid last name' }]}
            >
              <Input placeholder="Last Name" maxLength={256} />
            </Form.Item>
            <Form.Item
              label="Initials"
              required
              name="initials"
              className="form-input-initials"
              rules={[{ required: true, whitespace: true, message: 'Initials required' }]}
            >
              <Input placeholder="Initials" maxLength={256} />
            </Form.Item>
          </div>
          <div className="form-input-email-wrapper">
            <Form.Item
              label="Email address"
              name="email"
              required
              className="form-input-email"
              validateTrigger="onBlur"
              rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input placeholder="Email address" type="email" maxLength={256} />
            </Form.Item>
          </div>
          <div className="form-signature-paint">
            <div className="form-signature-paint-fullname">
              {firstName && firstName} {lastName && lastName}
            </div>
            <div className="form-signature-paint-initials">{initials && initials}</div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default WithSignatureModal;
