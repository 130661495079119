import { Heading, Paragraph } from 'components';
import { useTranslation } from 'react-i18next';
import './styles.less';
import { CreateDocumentButton } from '../create-document-button';

export function EmptyState() {
  const { t } = useTranslation();
  return (
    <div className="pipeline__empty_state_container">
      <div className="pipeline__empty_state_content">
        <img src="https://media.giphy.com/media/YqR8igkpXdtJKobl6f/giphy.gif" className="pipeline__empty_state_gif" />
        <Heading level={3}>{t('pipeline.empty_state.title')}</Heading>
        <Paragraph size="md">{t('pipeline.empty_state.content_msg_1')}</Paragraph>
        <Paragraph size="md">{t('pipeline.empty_state.content_msg_2')}</Paragraph>
        <CreateDocumentButton />
      </div>
    </div>
  );
}
