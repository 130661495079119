import { DraggableGridBlockWrapper } from './DraggableGridBlockWrapper';
import { BlocksContentCollection } from '../grid/reduxStore/editorSlice';
import { BlockConfig } from './models/BlockConfig.model';
import { GridBlockType } from '../shared/gridBlockType';
import './style.less';
interface GridImageBlockProps {
  onDragStop?: (e, data, item: BlockConfig) => void;
  onResizeStop: (e, direction, ref, delta, item: BlockConfig) => void;
  handleStop: (e, data, item: BlockConfig) => void;
  handleDrag: (e, block: BlockConfig) => void;
  handleDragStart: (e, data) => void;
  gridBlocks: BlocksContentCollection;
  gridPixelSize: number;
}

export const GridImageBlock = ({
  gridBlocks,
  gridPixelSize,
  handleDrag,
  handleStop,
  onResizeStop,
  handleDragStart,
}: GridImageBlockProps) => {
  return (
    <>
      {Object.values(gridBlocks)
        .filter((gridBlock) => gridBlock.type === GridBlockType.IMAGE)
        .map((gridImageBlock) => {
          return (
            <DraggableGridBlockWrapper
              key={`grid-image-${gridImageBlock.blockConfig.id}`}
              data-testid={`grid-image-${gridImageBlock.blockConfig.id}`}
              blockId={gridImageBlock.blockConfig.id}
              gridSystemInPx={gridPixelSize}
              handleStop={handleStop}
              handleDrag={handleDrag}
              handleResizeStop={onResizeStop}
              handleDragStart={handleDragStart}
            >
              <img
                key={`image-${gridImageBlock.blockConfig.id}`}
                data-testid={`image-${gridImageBlock.blockConfig.id}`}
                src={gridImageBlock.content}
                alt={`image-${gridImageBlock.blockConfig.id}`}
                className="grid_image_style"
                draggable={false}
              />
            </DraggableGridBlockWrapper>
          );
        })}
    </>
  );
};
