import { useSectionHeader } from 'hooks/useSectionHeader';
import TabbedNavigation from '../tabbednavigation';
import SectionTitle from '../sectiontitle';
import AvatarButton from '../avatar';
import HelpButton from '../helpbutton/helpbutton';
import WorkspaceButton from '../workspaceButton';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from '../../utils/featureFlags';
import './styles.less';
import { SubmenuItems } from 'components/tabbednavigation/interface';

export interface Prop {
  title: string;
  submenuItems?: SubmenuItems;
}

const DesktopSectionHeader = ({ title, submenuItems }: Prop) => {
  const hasSubmenuItems = submenuItems != undefined && submenuItems.length > 0;
  const { shouldShowSectionHeader } = useSectionHeader();
  const featureFlags = useFeatureFlag([FeatureFlags.multiaccounts]);
  const isFetchingFeatureFlags = Object.keys(featureFlags).length === 0;
  const hasWorkspaceAccess = featureFlags[FeatureFlags.multiaccounts];

  return (
    <>
      <div className="topbar">
        {shouldShowSectionHeader() && <SectionTitle>{title}</SectionTitle>}

        <div className="right-side">
          <AvatarButton />
          {!isFetchingFeatureFlags && hasWorkspaceAccess && <WorkspaceButton />}
          <HelpButton />
        </div>
      </div>

      {hasSubmenuItems && (
        <div role="menubar">
          <TabbedNavigation mode="horizontal" items={submenuItems} />
        </div>
      )}
    </>
  );
};

export default DesktopSectionHeader;
