import { createSlice } from '@reduxjs/toolkit';
import { UsedVariable } from '../../SidePanel/Variables/interfaces';

type UsedVariablesType = { [key: string]: Set<UsedVariable> };

export type UsedVariableState = {
  usedVariables: UsedVariablesType;
};

const initialState: UsedVariableState = {
  usedVariables: {},
};

const usedVariablesSlice = createSlice({
  name: 'editor-grid-block-used-variables',
  initialState,
  reducers: {
    handleAllUsedVariables(state, { payload }) {
      const { gridBlockId, usedVariables } = payload;
      const updatedUsedVariables = { ...state.usedVariables };
      updatedUsedVariables[gridBlockId] = usedVariables;
      state.usedVariables = updatedUsedVariables;
    },
  },
});

export const { handleAllUsedVariables } = usedVariablesSlice.actions;
export const usedVariablesReducer = usedVariablesSlice.reducer;
