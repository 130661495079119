import { Events } from './SocketEvents';
import { TSocketCallback } from './SocketClient';

type GridSocketCallback = (data: any[]) => void;

export default class GridSocketClient {
  constructor() {
    this.handleGridEvents = this.handleGridEvents.bind(this);
  }

  private handleGridEvents(callback: GridSocketCallback, event: Events) {
    (this as any).subscribe(event, (data: any[]) => {
      callback(data);
    });
  }

  public publishGridAdded(this: any, data: any, event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }

  public publishGridDeleted(this: any, data: any, event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }

  public publishGridContentChanged(this: any, data: any, event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }

  public publishGridPositionChanged(this: any, data: any, event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }
  public publishGridLayerChanged(this: any, data: any, event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }
  public publishGridDimensionChanged(this: any, data: any, event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }
  public getGridInitialContentLoad(this: any, data: any, event: string, callback: TSocketCallback) {
    this.publish(event, data, callback);
  }
}
