import { createSlice } from '@reduxjs/toolkit';
import { BlockConfig } from 'components/editor/GridDndEditor/models/BlockConfig.model';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { gridDefaultGreaterZAxis, gridDefaultLowerZAxis } from '../../shared/gridConfig';

type Collection<T> = {
  [key: string]: T;
};

export type BlockContent = {
  content: string;
  blockConfig: BlockConfig;
  type: GridBlockType;
};

export type BlockMetadata = {
  id: string;
  type: GridBlockType;
};
export type BlocksContentCollection = Collection<BlockContent>;

export type BlocksMetadataCollection = BlockMetadata[];

export type GridState = {
  blocksContent: BlocksContentCollection;
  blocksMetadata: BlocksMetadataCollection;
  blocksLayer: { greaterZIndexAvailable: number; lowerZIndexAvailable: number };
};

const initialState: GridState = {
  blocksContent: {},
  blocksMetadata: [],
  blocksLayer: { greaterZIndexAvailable: gridDefaultGreaterZAxis, lowerZIndexAvailable: gridDefaultLowerZAxis },
};

const gridBlockSlice = createSlice({
  name: 'editor-grid-block',
  initialState,
  reducers: {
    addGridBlockState(state, { payload }) {
      const { blockId, content, blockConfig, blockType } = payload;
      state.blocksMetadata.push({ id: blockId, type: blockType });
      state.blocksContent[blockId] = {
        blockConfig: blockConfig,
        content: content,
        type: blockType,
      };
      state.blocksLayer.greaterZIndexAvailable++;
      return state;
    },
    updateGridBlockState(state, { payload }) {
      const { blockId, content } = payload;
      state.blocksContent[blockId].content = content;
    },
    updateGridPositionConfig(state, { payload }) {
      const { blockId, x, y } = payload;
      state.blocksContent[blockId].blockConfig.x = x;
      state.blocksContent[blockId].blockConfig.y = y;
    },
    updateGridLayerConfig(state, { payload }: { payload: { blockId: string; zIndex: number } }) {
      const { blockId, zIndex } = payload;
      state.blocksContent[blockId].blockConfig.z = zIndex;

      const isBlockMovedToFrontLayer = zIndex === state.blocksLayer.greaterZIndexAvailable;
      const isBlockMovedToBackLayer = zIndex === state.blocksLayer.lowerZIndexAvailable;
      if (isBlockMovedToFrontLayer) {
        state.blocksLayer.greaterZIndexAvailable++;
      } else if (isBlockMovedToBackLayer) {
        state.blocksLayer.lowerZIndexAvailable--;
      }
    },
    updateGridDimensionConfig(state, { payload }) {
      const { blockId, width, height } = payload;
      state.blocksContent[blockId].blockConfig.width = width;
      state.blocksContent[blockId].blockConfig.height = height;
    },
    deleteGridBlockState(state, { payload }) {
      const { blockId } = payload;
      const blockIndex = state.blocksMetadata.findIndex((blockMetadata) => blockMetadata.id === blockId);

      const isBlockInFrontLayer = state.blocksContent[blockId].blockConfig.z === state.blocksLayer.greaterZIndexAvailable - 1;
      const isBlockInBackLayer = state.blocksContent[blockId].blockConfig.z === state.blocksLayer.lowerZIndexAvailable + 1;
      if (isBlockInFrontLayer) {
        state.blocksLayer.greaterZIndexAvailable = state.blocksContent[blockId].blockConfig.z;
      } else if (isBlockInBackLayer) {
        state.blocksLayer.lowerZIndexAvailable = state.blocksContent[blockId].blockConfig.z;
      }

      state.blocksMetadata.splice(blockIndex, 1);
      delete state.blocksContent[blockId];
    },
    resetState(state) {
      state = {
        blocksContent: {},
        blocksMetadata: [],
        blocksLayer: { greaterZIndexAvailable: gridDefaultGreaterZAxis, lowerZIndexAvailable: gridDefaultLowerZAxis },
      };
      return state;
    },
    setInitialState(state, { payload }) {
      const { blocksContent, blocksMetadata, blocksLayer } = payload;
      state.blocksContent = blocksContent;
      state.blocksMetadata = blocksMetadata;
      state.blocksLayer = blocksLayer;
    },
  },
});

export const {
  addGridBlockState,
  updateGridBlockState,
  updateGridPositionConfig,
  updateGridLayerConfig,
  updateGridDimensionConfig,
  deleteGridBlockState,
  resetState,
  setInitialState,
} = gridBlockSlice.actions;
export const gridBlockReducer = gridBlockSlice.reducer;
