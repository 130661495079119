import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'components';
import { URLS } from 'utils/urls';
import './styles.less';
import { Dropdown, MenuProps } from 'antd';
import { constructCinderUrl } from 'utils/constructCinderUrl';
import { CINDER_URLS } from 'utils/cinder-urls';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useNavigate } from 'react-router-dom';
import { BetaBadge } from '../beta-badge';
import { Link } from 'react-router-dom';

export function CreateDocumentButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const featureFlags = useFeatureFlag([FeatureFlags.editorV3, FeatureFlags.documentsV3]);
  const isEditorV3Enabled = featureFlags[FeatureFlags.editorV3];
  const isDocumentsV3Enabled = featureFlags[FeatureFlags.documentsV3];

  const items: MenuProps['items'] = [
    {
      label: <a href={constructCinderUrl(CINDER_URLS.createDocument)}>{t('pipeline.create_document_button.dropdown_editor_v2')}</a>,
      key: '1',
    },
    {
      label: (
        <Link to={URLS.document}>
          <BetaBadge />
          {t('pipeline.create_document_button.dropdown_editor_v3')}
        </Link>
      ),
      key: '2',
    },
  ];
  return isDocumentsV3Enabled && isEditorV3Enabled ? (
    <Dropdown
      overlayClassName="create-document-dropdown"
      menu={{ items }}
      trigger={['click']}
      dropdownRender={(menu) => <>{React.cloneElement(menu as React.ReactElement)}</>}
    >
      <Button
        icon={<Icon name="IcoPencil" />}
        className="create-document-button"
        type="primary"
        variant="positive"
        onClick={(e: MouseEvent) => e.preventDefault()}
        data-testid="create-document-button"
      >
        {t('pipeline.create_document_button.title')}
      </Button>
    </Dropdown>
  ) : (
    <Button
      icon={<Icon name="IcoPencil" />}
      className="create-document-button"
      type="primary"
      variant="positive"
      onClick={() => {
        isEditorV3Enabled ? navigate(URLS.document) : navigate(CINDER_URLS.createDocument);
      }}
      data-testid="create-document-button"
    >
      {t('pipeline.create_document_button.title')}
    </Button>
  );
}
