import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { defaultCallback, SocketClientInterface } from '../../../../services/socket/SocketClient';
import { GridDeleteType } from './saveReducers';

export const deleteBlockWithSocket = createAsyncThunk(
  'editor-grid-block/deleteGridWithSocket',
  async (payload: { gridBlockId: string; socketClient: SocketClientInterface; userId: number; callback?: (response) => void }) => {
    const { gridBlockId, callback = defaultCallback, socketClient } = payload;

    const data: GridDeleteType = {
      gridId: gridBlockId,
    };
    return new Promise<PayloadAction<string>>((resolve) => {
      socketClient.deleteGrid(data, (response) => {
        callback(response);
        resolve({ payload: 'Grid Deleted successfully', type: 'editor-grid-block/deleteGridWithSocket/fulfilled' });
      });
    });
  }
);
