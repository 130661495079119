export const gridPixelSize = 1;
export const gridPageMaxWidthInPixels = 794;
export const gridPageMinHeightInPixels = 1123;

export const gridDefaultWidth = 712;
export const gridDefaultHeight = 24;
export const gridDefaultXAxis = 50;
export const gridDefaultYAxis = 66;
export const gridDefaultGreaterZAxis = 0;
export const gridDefaultLowerZAxis = 0;

export const gridDefaultHeightOnDrop = 250;
export const gridDefaultWidthOnDrop = 250;
