import React, { useRef } from 'react';
import { Layout } from 'antd';
import { SocketClientInterface } from 'services/socket/SocketClient';
import { SaveStatus } from '../providers/SignaturesProvider';
import FroalaEditor from 'react-froala-wysiwyg';
import { PageBreaks } from '../pagebreaks';
import { useHandlePageBreaks } from 'hooks/useHandlePageBreaks';
import { GridDndEditor } from '../GridDndEditor';
import {
  GridBlockAddHandler,
  GridBlockChangeHandler,
  GridBlockDimensionChangeHandler,
  GridBlockPositionChangeHandler,
} from '../GridDndEditor/GridDndEditor';
import { useAppDispatch } from '../grid/reduxStore/store';
import {
  addGridBlockState,
  updateGridBlockState,
  updateGridDimensionConfig,
  updateGridPositionConfig,
} from '../grid/reduxStore/editorSlice';
import { addGridBlockWithSocket, saveConfigWithSocket, saveContentWithSocket } from '../grid/reduxStore/saveReducers';
import { Events } from '../../../services/socket/SocketEvents';
import { useCurrentUser } from 'providers/UserProvider';
import { Block } from '../GridDndEditor/models/Block.model';
import { GridBlockType } from '../shared/gridBlockType';
import { EditorConfig } from '../helpers/config';

const { Content } = Layout;

interface EditorContentProps {
  documentId: string;
  socketClient: SocketClientInterface;
  setRef: (node: FroalaEditor | null) => void;
  editorConfig: EditorConfig;
  setContentSaveStatus: React.Dispatch<React.SetStateAction<SaveStatus>>;
  gridRef: React.RefObject<HTMLDivElement>;
  toolbarButtons?: any;
}

const EditorContent: React.FC<EditorContentProps> = ({ documentId, socketClient, editorConfig, gridRef }) => {
  const pageRef = useRef<HTMLDivElement>(null);
  const { pageBreaks } = useHandlePageBreaks(pageRef);
  const dispatch = useAppDispatch();
  const { data } = useCurrentUser();
  const userId = data.id;

  const gridBlockAddHandler: GridBlockAddHandler = (documentContent: string, blockConfig: Block) => {
    dispatch(
      addGridBlockWithSocket({
        userId,
        content: documentContent,
        blockConfig,
        socketClient,
        type: GridBlockType.TEXT,
        callback: (response) => {
          const gridBlockId = JSON.parse(response).content.gridId;
          const updatedConfig = {
            ...blockConfig,
            id: gridBlockId,
          };
          const payload = {
            content: documentContent,
            blockConfig: updatedConfig,
            blockId: gridBlockId,
            blockType: GridBlockType.TEXT,
          };
          dispatch(addGridBlockState(payload));
        },
      })
    );
  };

  const gridBlockChangeHandler: GridBlockChangeHandler = (gridBlockId: string, documentContent: string) => {
    const payload = {
      blockId: gridBlockId,
      content: documentContent,
    };
    dispatch(updateGridBlockState(payload));
    dispatch(
      saveContentWithSocket({
        gridBlockId: gridBlockId,
        content: documentContent,
        userId: userId,
        socketClient: socketClient,
      })
    );
  };
  const gridBlockPositionChangeHandler: GridBlockPositionChangeHandler = (gridBlockId, x, y) => {
    const payload = {
      blockId: gridBlockId,
      x,
      y,
    };

    dispatch(updateGridPositionConfig(payload));
    dispatch(
      saveConfigWithSocket({
        gridBlockId,
        x,
        y,
        userId: userId,
        eventType: Events.GRID_POSITION_CHANGED,
        socketClient,
      })
    );
  };

  const gridBlockDimensionChangeHandler: GridBlockDimensionChangeHandler = (gridBlockId, width, height) => {
    const payload = {
      blockId: gridBlockId,
      width,
      height,
    };

    dispatch(updateGridDimensionConfig(payload));
    dispatch(
      saveConfigWithSocket({
        gridBlockId,
        width,
        height,
        userId: userId,
        eventType: Events.GRID_DIMENSION_CHANGED,
        socketClient,
      })
    );
  };

  return (
    <Content ref={pageRef}>
      <GridDndEditor
        documentId={documentId}
        gridRef={gridRef}
        configOptions={editorConfig}
        gridBlockAddHandler={gridBlockAddHandler}
        gridBlockChangeHandler={gridBlockChangeHandler}
        gridBlockPositionChangeHandler={gridBlockPositionChangeHandler}
        gridBlockDimensionChangeHandler={gridBlockDimensionChangeHandler}
      />
      <PageBreaks pageBreaks={pageBreaks} />
    </Content>
  );
};

export default EditorContent;
