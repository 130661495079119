import React, { ReactNode } from 'react';
import { Rnd } from 'react-rnd';
import Draggable from 'react-draggable';
import { default as DragIcon } from '../../icon/icons/IcoDrag';
import { default as ResizeIcon } from '../../icon/icons/IcoGridResize';
import { BlockConfig } from './models/BlockConfig.model';
import GridDeleteComponent from './GridDeleteComponent';
import { useSelector } from 'react-redux';
import { RootState, rootStore } from '../grid/reduxStore/store';
import ContentClipper from './Clipper/ContentClipper';
import { useInViewport } from './useInViewport';
import { CustomContextMenu, CustomContextMenuOptions } from '../../CustomContextMenu/CustomContextMenu';
import useGridBlockLayer from './UseGridBlockLayer';

export interface DraggableGridBlockWrapperProps {
  blockId: string;
  children?: ReactNode;
  gridSystemInPx: number;
  handleResizeStop: (e, direction, ref, delta, item: BlockConfig) => void;
  handleStop: (e, data, item: BlockConfig) => void;
  handleDrag: (e, item: BlockConfig) => void;
  handleDragStart: (e, data) => void;
  style?: React.CSSProperties | undefined;
}
export const DraggableGridBlockWrapper: React.FC<DraggableGridBlockWrapperProps> = ({
  blockId,
  children,
  gridSystemInPx,
  handleResizeStop,
  handleDrag,
  handleDragStart,
  handleStop,
  style,
}) => {
  const { bringGridToFront, bringGridForward, sendGridBackward, sendGridToBack } = useGridBlockLayer();
  const blocksContent = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksContent[blockId];
  });
  const { scrollToPosition, ref: scrollElRef } = useInViewport();

  if (!blocksContent) {
    return null;
  }
  const handleOnDrag = (e, data) => {
    const { x, y } = data;
    scrollToPosition(y);
    handleDrag(e, {
      ...blocksContent.blockConfig,
      x,
      y,
    });
  };

  const isMac = navigator.userAgent.includes('Mac');
  const notAllowUsersToHighlightText = false;

  const customContextMenuOptions: CustomContextMenuOptions = [
    {
      title: 'Bring to front',
      callback: () => bringGridToFront(blocksContent.blockConfig, rootStore.getState().gridBlockReducer.blocksLayer.greaterZIndexAvailable),
    },
    {
      title: 'Bring forward',
      callback: () => bringGridForward(blocksContent.blockConfig, rootStore.getState().gridBlockReducer.blocksContent),
    },
    {
      title: 'Send backward',
      callback: () => sendGridBackward(blocksContent.blockConfig, rootStore.getState().gridBlockReducer.blocksContent),
    },
    {
      title: 'Send to back',
      callback: () => sendGridToBack(blocksContent.blockConfig, rootStore.getState().gridBlockReducer.blocksLayer.lowerZIndexAvailable),
    },
  ];

  return (
    <CustomContextMenu options={customContextMenuOptions}>
      <div style={{ zIndex: blocksContent.blockConfig.z, position: 'relative' }}>
        <Draggable
          defaultClassName={`editor__page__draggable ${isMac && 'is_mac'}`}
          position={{ x: blocksContent.blockConfig.x, y: blocksContent.blockConfig.y }}
          grid={[gridSystemInPx, gridSystemInPx]}
          scale={1}
          onStop={(e, data) =>
            handleStop(e, data, {
              ...blocksContent.blockConfig,
              x: data.x,
              y: data.y,
            })
          }
          onDrag={(e, data) => handleOnDrag(e, data)}
          onStart={(e, data) =>
            handleDragStart(e, {
              ...blocksContent.blockConfig,
              x: data.x,
              y: data.y,
            })
          }
          enableUserSelectHack={notAllowUsersToHighlightText}
        >
          <Rnd
            size={{
              width: blocksContent.blockConfig.width || 0,
              height: blocksContent.blockConfig.height || gridSystemInPx,
            }}
            position={{
              x: blocksContent.blockConfig.x,
              y: blocksContent.blockConfig.y,
            }}
            minWidth={gridSystemInPx}
            style={style}
            minHeight={gridSystemInPx}
            dragGrid={[gridSystemInPx, gridSystemInPx]}
            resizeGrid={[gridSystemInPx, gridSystemInPx]}
            resizeHandleClasses={{ bottomRight: 'editor__page__draggable__grid-resize-handle' }}
            enableResizing={{ bottomRight: true }}
            onResize={(e, direction, ref, delta) => handleResizeStop(e, direction, ref, delta, blocksContent.blockConfig)}
            onResizeStop={(e, direction, ref, delta) => handleResizeStop(e, direction, ref, delta, blocksContent.blockConfig)}
            enableUserSelectHack={notAllowUsersToHighlightText}
          >
            <div data-testid="grid_block_drag_handle_test" className="editor__page__draggable__grid-drag-handle" ref={scrollElRef}>
              <DragIcon />
            </div>
            <div data-testid="grid_block_resize_handle_test" className="editor__page__draggable__grid-resize-handle-icon">
              <ResizeIcon />
            </div>
            <GridDeleteComponent gridBlockId={blockId} />
            {children}
            <ContentClipper
              xAxis={blocksContent.blockConfig.x}
              yAxis={blocksContent.blockConfig.y}
              width={blocksContent.blockConfig.width || 0}
            />
          </Rnd>
        </Draggable>
      </div>
    </CustomContextMenu>
  );
};

DraggableGridBlockWrapper.displayName = 'DraggableGridBlockWrapper';
