import React, { useEffect, useState, useContext, useRef } from 'react';
import './DocumentBuilder.less';
import './variables/variablesList.less';
import { SaveStatus } from './providers/SignaturesProvider';
import { Layout } from 'antd';
import { SocketClientInterface } from 'services/socket/SocketClient';
import FontLoader from '../font';
import { accessToken } from 'services/authorization/handleTokenCookie';
import useEditorRef from 'hooks/useEditorRef';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import PageLoader from 'components/page-loader/page-loader';
import { EditorSidePanel } from './SidePanel';
import DocumentLockedModal from './modals/DocumentLockedModal';
import { URLS } from 'utils/urls';
import { ErrorCode } from 'services/socket/SocketEvents';
import { useNavigate } from 'react-router-dom';
import { DocumentSaveStatus } from './helpers/DocumentSaveStatus';
import Froala from 'froala-editor';
import { undoPlugin } from './custom-plugins/undo/undo.plugin';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { EditorContent } from './EditorContent';
import { SignaturesContext } from 'components/editor/providers/SignaturesProvider';
import { editorConfig as defaultEditorConfig } from './helpers/config';
import { DndProvider } from 'react-dnd';
import usePreviewRedirect from '../../pages/editor/usePreviewRedirect';
import { setInitialState, resetState } from './grid/reduxStore/editorSlice';
import { useAppDispatch } from './grid/reduxStore/store';
import { GridAddType } from './grid/reduxStore/saveReducers';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import { CINDER_URLS } from 'utils/cinder-urls';
import { convertGridResponseToGridContentBlocks } from './GridDndEditor/gridHelper';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { CONFIG } from 'config';
import { dragDropManager } from './dndContext';
import EditorMenu from './editor-menu/EditorMenu';
import { Block } from './GridDndEditor/models/Block.model';
import { gridDefaultGreaterZAxis, gridDefaultHeight, gridDefaultWidth, gridDefaultXAxis, gridDefaultYAxis } from './shared/gridConfig';
import { useGridBlockAddHandler } from './EditorContent/UseGridBlockAddHandler';

interface DocumentBuilderProps {
  documentId: string;
  socketClient: SocketClientInterface;
}

const DocumentBuilder: React.FC<DocumentBuilderProps> = ({ documentId, socketClient }) => {
  const {
    data: documentData,
    isFetching,
    isFetched,
    isFetchedAfterMount,
    status: documentLoadingStatus,
  } = useGetDocumentByIdQuery(documentId);
  const [contentSaveStatus, setContentSaveStatus] = useState<SaveStatus>({ status: DocumentSaveStatus.SAVED, errCode: '' });
  const [editorConfig, setEditorConfig] = useState<any>(undefined);
  const [isConfigLoaded, setIsConfigLoaded] = useState<boolean>(false);
  const toolbarButtons = useRef<string[]>([]);
  const { signatureSaveStatus, setSignaturesOnMount } = useContext(SignaturesContext);
  const { editorRef, setRef } = useEditorRef();
  const [isSignatureLoaded, setIsSignatureLoaded] = useState<boolean>(false);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const handlePreviewRedirect = usePreviewRedirect(documentId);
  const handlePipelineRedirect = () => navigate(URLS.pipeline);
  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.centralizedUndoRedo, FeatureFlags.chatGPT]);
  const dispatch = useAppDispatch();
  const [gridBlockAddHandler] = useGridBlockAddHandler();
  const isBlockCreated = useRef(false);

  const editorConfigHandler = (newConfig) => {
    setEditorConfig(newConfig);
    setIsConfigLoaded(true);
  };

  useEffect(() => {
    if (signatureSaveStatus.status !== contentSaveStatus.status) {
      setContentSaveStatus(signatureSaveStatus);
    }
  }, [signatureSaveStatus.status, signatureSaveStatus.errCode]);

  const gridLoadCallback = (data: string) => {
    const { content: gridsData }: { content: GridAddType[] } = JSON.parse(data);
    if (gridsData.length > 0) {
      const blocks = convertGridResponseToGridContentBlocks(gridsData);
      dispatch(
        setInitialState({
          documentId: documentId,
          blocksContent: blocks.blocksContent,
          blocksMetadata: blocks.blocksMetadata,
          blocksLayer: blocks.blocksLayer,
        })
      );
    } else {
      if (isBlockCreated.current) return;
      const blockConfig: Block = {
        width: gridDefaultWidth,
        height: gridDefaultHeight,
        x: gridDefaultXAxis,
        y: gridDefaultYAxis,
        z: gridDefaultGreaterZAxis,
      };

      gridBlockAddHandler(documentId, '', blockConfig);
      isBlockCreated.current = true;
    }
  };

  useEffect(() => {
    const editorUrl = process.env.REACT_APP_EDITOR_SERVER as string;
    socketClient.connect(editorUrl, { documentId, accessToken });

    return () => {
      socketClient.disconnect();
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    socketClient.getAllSignaturesOnMount((data) => {
      setSignaturesOnMount(data);
      setIsSignatureLoaded(true);
    });

    socketClient.getGridInitialContentLoad(gridLoadCallback);
  }, [socketClient.getConnectionStatus() === true]);

  useEffect(() => {
    toolbarButtons.current = defaultEditorConfig['toolbarButtons'];
    if (evaluatedFeatureFlags[FeatureFlags.centralizedUndoRedo] === true) {
      toolbarButtons.current = defaultEditorConfig['toolbarButtons'].map((item) => {
        switch (item) {
          case 'undo':
            return 'centralizedUndo';
          case 'redo':
            return 'centralizedRedo';
          default:
            return item;
        }
      });

      Froala.PLUGINS.undoPlugin = undoPlugin;
      Froala.RegisterCommand('centralizedUndo', {
        title: 'undo',
        icon: 'undo',
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        callback: function () {
          this.undoPlugin.undo();
        },
        refresh: function ($btn) {
          $btn.toggleClass('fr-disabled', !this.undoPlugin.canUndo());
        },
      });
      Froala.RegisterCommand('centralizedRedo', {
        title: 'redo',
        icon: 'redo',
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        callback: function () {
          this.undoPlugin.redo();
        },
        refresh: function ($btn) {
          $btn.toggleClass('fr-disabled', !this.undoPlugin.canRedo());
        },
      });
    }
    //ChatGPT Button
    if (evaluatedFeatureFlags[FeatureFlags.chatGPT]) {
      Froala.DefineIcon('chatGPT', { NAME: 'search', SVG_KEY: 'search' });
      Froala.RegisterCommand('chatGPT', {
        title: 'Ask ChatGPT',
        icon: 'chatGPT',
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: async function callback() {
          const data = {
            model: 'gpt-3.5-turbo',
            max_tokens: 256,
            temperature: 0,
            messages: [
              {
                role: 'system',
                content: 'You are a world class sales leader and you help write sales proposals',
              },
              {
                role: 'user',
                content: this.selection.text(),
              },
            ],
          };
          // Make the API call to ChatGPT
          const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${CONFIG.CHAT_GPT_KEY}`,
            },
            body: JSON.stringify(data),
          });
          const { choices } = await response.json();
          // replace the suggestion into selected text
          this.html.insert(choices[0].message.content);
        },
      });
    }
  }, [evaluatedFeatureFlags]);

  if (documentLoadingStatus === 'error') {
    return <NavigateToCinder path={CINDER_URLS.error} />;
  }
  return (
    <PageLoader isLoading={isFetching} isLoaded={isFetched && isFetchedAfterMount}>
      <DocumentLockedModal
        isDocumentLocked={
          documentData?.isLocked ||
          signatureSaveStatus.errCode === ErrorCode.LOCKED ||
          contentSaveStatus.errCode === ErrorCode.LOCKED ||
          false
        }
        handleOk={handlePreviewRedirect}
        handleCancel={handlePipelineRedirect}
      />
      <header className="editor-header-wrapper">
        <EditorMenu
          documentId={documentId}
          documentTitle={documentData?.title || 'Document Title'}
          price={documentData?.price || 0}
          status={documentData?.status || 'DRAFT'}
          documentStatus={contentSaveStatus.status}
          handleDocumentContentStatus={setContentSaveStatus}
        />
        <FontLoader editorConfigHandler={editorConfigHandler} />
      </header>
      <DndProvider manager={dragDropManager}>
        <Layout className="editor-document-builder">
          <Content className="editor-document-main-content">
            {isSignatureLoaded && isConfigLoaded && (
              <EditorContent
                documentId={documentId}
                socketClient={socketClient}
                setRef={setRef}
                editorConfig={editorConfig}
                setContentSaveStatus={setContentSaveStatus}
                gridRef={gridRef}
              />
            )}
          </Content>
          <Sider theme="light" width={''} className="editor-right-sider">
            <EditorSidePanel documentId={documentId} editorRef={editorRef} />
          </Sider>
        </Layout>
      </DndProvider>
    </PageLoader>
  );
};

export default DocumentBuilder;
