import React from 'react';
import Button from '../button';
import './styles.less';
import { Dropdown, Avatar, MenuProps } from 'antd';
import { useCurrentUser } from 'providers/UserProvider';
import { constructCinderUrl } from 'utils/constructCinderUrl';
import { CINDER_URLS } from 'utils/cinder-urls';
import { FeaturesToggle } from './featuresToggle';

interface Props {
  disabled?: boolean;
}

const AvatarButton: React.FC<Props> = ({ disabled = false }) => {
  const { data } = useCurrentUser();
  const { avatar, fullName = '', id: userId } = data;
  const profileImage = !avatar?.includes('img/blank.gif') ? avatar : null;
  const initial = fullName.charAt(0).toUpperCase();

  const items: MenuProps['items'] = [
    { label: <a href={constructCinderUrl(CINDER_URLS.userEdit(userId))}>Profile</a>, key: '1' },
    { label: <a href={constructCinderUrl(CINDER_URLS.resourceGuides)}>Resources</a>, key: '2' },
    { label: <a href={constructCinderUrl(CINDER_URLS.logout)}>Logout</a>, key: '3' },
  ];

  return (
    <Dropdown
      overlayClassName="avatar-dropdown"
      menu={{ items }}
      trigger={['click']}
      disabled={disabled}
      dropdownRender={(menu) => (
        <>
          {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none' } })}
          <FeaturesToggle />
        </>
      )}
    >
      <Button className="avatar-button" variant="neutral" type="default" onClick={(e) => e.preventDefault()}>
        <Avatar data-testid="avatar-button" className="avatar-icon" src={profileImage}>
          {initial}
        </Avatar>
      </Button>
    </Dropdown>
  );
};

export default AvatarButton;
