import { ImageDrop } from '../ImageLibrary/ImageDrop';
import React, { useState } from 'react';
import SkeletonImageLoading from '../../skeletonImageLoading';
import { Block } from './models/Block.model';
import './style.less';
interface GridImageLoaderProps {
  documentId: string;
}

const editorSkeletonImageLoader = 'editor-skeleton-image-loader';

export const GridImageLoader: React.FC<GridImageLoaderProps> = ({ documentId }) => {
  const [loadingComponents, setLoadingComponents] = useState<Block[]>([]);

  return (
    <>
      <ImageDrop documentId={documentId} setLoadingComponents={setLoadingComponents} />
      {loadingComponents.map((component: Block, index) => {
        return (
          <SkeletonImageLoading
            key={index}
            imageKey={index}
            xAxis={component.x}
            yAxis={component.y}
            width={component.width || 0}
            height={component.height || 0}
            className={editorSkeletonImageLoader}
          />
        );
      })}
    </>
  );
};
