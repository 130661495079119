import React, { useEffect, useRef, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { EditorConfig, editorConfig } from '../helpers/config';
import { useTributeOnFroalaInit } from 'hooks/useTributeOnFroalaInit';
import { useGetVariables } from '../hooks/useVariables';
import { useUsedVariables } from '../../../hooks/useUsedVariables';
import { UsedVariable } from '../SidePanel/Variables/interfaces';
import { useDispatch } from 'react-redux';
import { handleAllUsedVariables } from './reduxStore/usedVariablesSlice';

export type GridBlockContentChangeHandler = (gridBlockId: string, content: string) => void;
export interface FroalaEditorProps {
  documentId: string;
  gridBlockId: string;
  configOptions?: EditorConfig;
  documentContent?: string;
  toolbarButtons?: any;
  editorResizeHandler?: (editorId: string, rect: DOMRectReadOnly) => void;
  contentChangeHandler?: GridBlockContentChangeHandler;
}

const FroalaTextbox: React.FC<FroalaEditorProps> = ({
  documentId,
  gridBlockId,
  configOptions,
  documentContent: initialDocumentContent,
  toolbarButtons,
  editorResizeHandler,
  contentChangeHandler,
}: FroalaEditorProps) => {
  const editorId = `froala_editor_${gridBlockId}`;
  const { data: variables } = useGetVariables(documentId);
  const editorRef = useRef<FroalaEditor>(null);
  const debounceDelay = 100;

  useTributeOnFroalaInit({ variables, editorRef: editorRef });

  const { handleUsedVariablesParsing } = useUsedVariables();
  const dispatch = useDispatch();

  const [documentContent, setDocumentContent] = useState<string>(initialDocumentContent || '');
  const placeholderTextConfig = {
    placeholderText: editorConfig.placeholderText,
  };
  const handleUsedVariablesParsingCallback = (usedVariables: UsedVariable[]) => {
    dispatch(handleAllUsedVariables({ gridBlockId, usedVariables }));
  };

  useEffect(() => {
    handleUsedVariablesParsing(documentContent, handleUsedVariablesParsingCallback);
    if (contentChangeHandler) {
      contentChangeHandler(gridBlockId, documentContent);
    }
  }, [documentContent]);

  const defaultConfig = {
    ...editorConfig,
    ...configOptions,
    ...placeholderTextConfig,
    ...{
      autofocus: true,
    },
  };

  if (toolbarButtons) {
    defaultConfig['toolbarButtons'] = toolbarButtons.current;
  }

  // Debounce function to limit the frequency of function calls
  const debounce = <T extends unknown[]>(func: (...args: T) => void, delay: number) => {
    let timeout: NodeJS.Timeout;
    return function (this: unknown, ...args: T) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const froalaEditorElement = document.querySelector('.' + editorId);
    if (!froalaEditorElement) return;

    const editorResizeHandlerDebounced = debounce((editorId: string, textbox: DOMRectReadOnly) => {
      if (editorResizeHandler) {
        editorResizeHandler(editorId, textbox);
      }
    }, debounceDelay);

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];

      // check if there are any initial value coming from drabble and trigger resizing
      if (documentContent) {
        editorResizeHandlerDebounced(gridBlockId, entry.contentRect);
      }
    });

    observer.observe(froalaEditorElement);
    return () => {
      observer.disconnect();
    };
  }, [documentContent]);
  const modelChangeHandler = (documentContent: string) => {
    setDocumentContent(documentContent);
  };

  return (
    <div className={editorId}>
      <FroalaEditor
        tag="textarea"
        config={{ ...defaultConfig }}
        ref={editorRef}
        onModelChange={modelChangeHandler}
        model={documentContent || ''}
      />
    </div>
  );
};

export default FroalaTextbox;
