import { BlockConfig } from '../models/BlockConfig.model';
import { TrackedBlockCollection } from '../models/TrackedBlockCollection.model';
import './DragLines.less';

interface DragLinesProps {
  currentBlock: BlockConfig | null;
  trackedBlockCollection: TrackedBlockCollection;
  maxWidthPx: number;
  maxHeightPx: number;
}

export default function DragLines({ currentBlock, trackedBlockCollection, maxWidthPx, maxHeightPx }: DragLinesProps) {
  if (!currentBlock) {
    return null;
  }

  const dragLineData = getDragLineData(currentBlock, trackedBlockCollection, maxWidthPx, maxHeightPx);

  return (
    <>
      {dragLineData.map((lineDataItem) => {
        const dashGap = lineDataItem.isMiddle ? 4 : 0;

        return lineDataItem.lines.map((line, index) => {
          return lineDataItem.direction === 'horizontal' ? (
            <svg key={`h-line-${index}`} className="dragline-container horizontal" viewBox={`0 0 ${maxWidthPx} ${maxHeightPx}`}>
              <line x1={0} y1={line} x2={maxWidthPx} y2={line} stroke={lineDataItem.color} strokeWidth="1px" strokeDasharray={dashGap} />
            </svg>
          ) : (
            <svg key={`v-line-${index}`} className="dragline-container vertical" viewBox={`0 0 ${maxWidthPx} ${maxHeightPx}`}>
              <line x1={line} y1={0} x2={line} y2={maxHeightPx} stroke={lineDataItem.color} strokeWidth="1px" strokeDasharray={dashGap} />
            </svg>
          );
        });
      })}
    </>
  );
}

function getDragLineData(
  currentBlock: BlockConfig,
  trackedBlockCollection: TrackedBlockCollection,
  maxWidthPx: number,
  maxHeightPx: number
) {
  const dragLineCollection = {
    horizontalEdgeLines: new Set<number>(),
    verticalEdgeLines: new Set<number>(),
    middleHorizontalLines: new Set<number>(),
    middleVerticalLines: new Set<number>(),
    pageHorizontalLines: new Set<number>(),
    pageVerticalLines: new Set<number>(),
  };

  for (const [id, trackedData] of Object.entries(trackedBlockCollection)) {
    if (id === currentBlock?.id) {
      continue;
    }

    const draggedBlockBottom = currentBlock?.y + (currentBlock?.height || 0);
    const draggedBlockRight = currentBlock?.x + (currentBlock?.width || 0);
    const draggedBlockMiddleX = Math.round(currentBlock?.x + (currentBlock?.width || 0) / 2);
    const draggedBlockMiddleY = Math.round(currentBlock?.y + (currentBlock?.height || 0) / 2);
    const trackedBlockBottom = trackedData.y + (trackedData?.height || 0);
    const trackedBlockRight = trackedData.x + (trackedData?.width || 0);
    const trackedBlockMiddleX = Math.round(trackedData.x + (trackedData?.width || 0) / 2);
    const trackedBlockMiddleY = Math.round(trackedData.y + (trackedData?.height || 0) / 2);
    const pageMiddleX = Math.round(maxWidthPx / 2);
    const pageMiddleY = Math.round(maxHeightPx / 2);

    // left of draggingBlock, any X match on trackedBlock
    if (currentBlock?.x === trackedData.x || currentBlock?.x === trackedBlockRight || currentBlock?.x === trackedBlockMiddleX) {
      dragLineCollection.verticalEdgeLines.add(currentBlock?.x);
    }

    // right of draggingBlock, any X match on trackedBlock
    if (draggedBlockRight === trackedData.x || draggedBlockRight === trackedBlockRight || draggedBlockRight === trackedBlockMiddleX) {
      dragLineCollection.verticalEdgeLines.add(draggedBlockRight);
    }

    // middle of dragging block, any X match on trackedBlock
    if (draggedBlockMiddleX === trackedData.x || draggedBlockMiddleX === trackedBlockRight || draggedBlockMiddleX === trackedBlockMiddleX) {
      dragLineCollection.middleVerticalLines.add(draggedBlockMiddleX);
    }

    // vertical middle of page, any X match on dragging block
    if (currentBlock.x === pageMiddleX || draggedBlockRight === pageMiddleX || draggedBlockMiddleX === pageMiddleX) {
      dragLineCollection.pageVerticalLines.add(pageMiddleX);
    }

    // top of draggingBlock, any Y match on trackedBlock
    if (currentBlock?.y === trackedData.y || currentBlock?.y === trackedBlockBottom || currentBlock?.y === trackedBlockMiddleY) {
      dragLineCollection.horizontalEdgeLines.add(currentBlock?.y);
    }

    // bottom of draggingBlock, any Y match on trackedBlock
    if (draggedBlockBottom === trackedData.y || draggedBlockBottom === trackedBlockBottom || draggedBlockBottom === trackedBlockMiddleY) {
      dragLineCollection.horizontalEdgeLines.add(draggedBlockBottom);
    }

    // middle of dragging block, any Y match on trackedBlock
    if (
      draggedBlockMiddleY === trackedData.y ||
      draggedBlockMiddleY === trackedBlockBottom ||
      draggedBlockMiddleY === trackedBlockMiddleY
    ) {
      dragLineCollection.middleHorizontalLines.add(draggedBlockMiddleY);
    }

    // horizontal middle of page, any Y match on dragging block
    if (currentBlock.y === pageMiddleY || draggedBlockBottom === pageMiddleY || draggedBlockMiddleY === pageMiddleY) {
      dragLineCollection.pageHorizontalLines.add(pageMiddleY);
    }
  }

  dragLineCollection.pageHorizontalLines.forEach((line) => {
    dragLineCollection.horizontalEdgeLines.delete(line);
    dragLineCollection.middleHorizontalLines.delete(line);
  });

  dragLineCollection.pageVerticalLines.forEach((line) => {
    dragLineCollection.verticalEdgeLines.delete(line);
    dragLineCollection.middleVerticalLines.delete(line);
  });

  return [
    {
      color: 'green',
      direction: 'horizontal',
      isMiddle: false,
      lines: Array.from(dragLineCollection.horizontalEdgeLines),
    },
    {
      color: 'green',
      direction: 'vertical',
      isMiddle: false,
      lines: Array.from(dragLineCollection.verticalEdgeLines),
    },
    {
      color: 'green',
      direction: 'horizontal',
      isMiddle: true,
      lines: Array.from(dragLineCollection.middleHorizontalLines),
    },
    {
      color: 'green',
      direction: 'vertical',
      isMiddle: true,
      lines: Array.from(dragLineCollection.middleVerticalLines),
    },
    {
      color: 'red',
      direction: 'horizontal',
      isMiddle: true,
      lines: Array.from(dragLineCollection.pageHorizontalLines),
    },
    {
      color: 'red',
      direction: 'vertical',
      isMiddle: true,
      lines: Array.from(dragLineCollection.pageVerticalLines),
    },
  ];
}
