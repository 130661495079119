import { forwardRef, ReactNode } from 'react';
import { GridBlockAddHandler, GridBlockChangeHandler, GridBlockPositionChangeHandler } from './GridDndEditor';
import { Rnd } from 'react-rnd';
import Draggable from 'react-draggable';
import { VariablesGroup } from '../../../services/entities/VariablesEntity';
import { BlockContent } from '../grid/reduxStore/editorSlice';
import { BlockConfig } from './models/BlockConfig.model';

export interface GridBlockProps {
  configOptions?: object | undefined;
  variables?: VariablesGroup;
  gridBlock: BlockContent;
  children?: ReactNode;
  gridSystemInPx: number;
  gridRef?: React.MutableRefObject<HTMLDivElement | null>;
  gridBlockAddHandler?: GridBlockAddHandler;
  gridBlockChangeHandler?: GridBlockChangeHandler;
  gridBlockPositionChangeHandler?: GridBlockPositionChangeHandler;
  onDragStop?: (e, data, item: BlockConfig) => void;
  onResizeStop?: (e, direction, ref, delta, item: BlockConfig) => void;
}

export const GridBlock = forwardRef<HTMLDivElement, GridBlockProps>(
  ({ gridBlock: block, children, gridSystemInPx, onDragStop, onResizeStop }) => {
    return (
      <div style={{ zIndex: block.blockConfig.z, position: 'relative' }}>
        <Draggable
          defaultClassName="editor__page__draggable"
          key={block.blockConfig.id}
          handle=".editor__page__grid-drag-handle"
          position={{ x: block.blockConfig.x, y: block.blockConfig.y }}
          grid={[gridSystemInPx, gridSystemInPx]}
          scale={1}
          onStop={(e, data) => {
            if (onDragStop) onDragStop(e, data, block.blockConfig);
          }}
        >
          <Rnd
            size={{
              width: block.blockConfig.width || gridSystemInPx,
              height: block.blockConfig.height || gridSystemInPx,
            }}
            position={{
              x: block.blockConfig.x,
              y: block.blockConfig.y,
            }}
            minWidth={gridSystemInPx}
            minHeight={gridSystemInPx}
            dragGrid={[gridSystemInPx, gridSystemInPx]}
            resizeGrid={[gridSystemInPx, gridSystemInPx]}
            dragHandleClassName="editor__page__grid-drag-handle"
            resizeHandleClasses={{ bottomRight: 'editor__page__grid-resize-handle' }}
            enableResizing={{ bottomRight: true }}
            onResize={(e, direction, ref, delta) => {
              if (onResizeStop) onResizeStop(e, direction, ref, delta, block.blockConfig);
            }}
            onResizeStop={(e, direction, ref, delta) => {
              if (onResizeStop) onResizeStop(e, direction, ref, delta, block.blockConfig);
            }}
          >
            {children}
          </Rnd>
        </Draggable>
      </div>
    );
  }
);

GridBlock.displayName = 'GridBlock';
