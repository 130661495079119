import { GridAddType } from '../grid/reduxStore/saveReducers';
import { gridPixelSize } from '../shared/gridConfig';
import { BlocksContentCollection, BlocksMetadataCollection, GridState } from '../grid/reduxStore/editorSlice';
import { getSignaturesMaxHeight } from '../SidePanel/Signatures/SignatureHelper';
import { SignatureBox } from 'services/repositories/interfaces/SignatureRepository';
import { BlockConfig } from './models/BlockConfig.model';

export const maxImageWidthAllowedInPx = 500;
export const maxImageHeightAllowedInPx = 500;

export function convertGridResponseToGridContentBlocks(gridsData: GridAddType[]): GridState {
  return gridsData.reduce(
    (reducedData, key) => {
      reducedData['blocksMetadata'].push({ id: key.gridId as string, type: key.type });
      reducedData['blocksContent'][key.gridId as string] = {
        content: key.htmlContent,
        type: key.type,
        blockConfig: {
          id: key.gridId || '0',
          height: key.dimensions.height_px,
          width: key.dimensions.width_px,
          y: key.position.top_px,
          x: key.position.left_px,
          z: key.zIndex,
        },
      };
      reducedData.blocksLayer.greaterZIndexAvailable = Math.max(key.zIndex + 1, reducedData.blocksLayer.greaterZIndexAvailable);
      reducedData.blocksLayer.lowerZIndexAvailable = Math.min(key.zIndex - 1, reducedData.blocksLayer.lowerZIndexAvailable);
      return reducedData;
    },
    {
      blocksContent: {} as BlocksContentCollection,
      blocksMetadata: [] as BlocksMetadataCollection,
      blocksLayer: { greaterZIndexAvailable: 0, lowerZIndexAvailable: 0 },
    }
  );
}

export function getEditorMaxHeight(blocksContent: BlocksContentCollection | object, ...otherMaxValues: number[]) {
  let gridBlockMaxHeight = 0;
  if (blocksContent) {
    gridBlockMaxHeight = Object.entries(blocksContent).reduce((currentMax, blockContent) => {
      return Math.max(currentMax, blockContent[1].blockConfig.y + (blockContent[1].blockConfig.height || 0));
    }, 0);
  }

  return Math.max(gridBlockMaxHeight, ...otherMaxValues) + gridPixelSize * gridPixelSize;
}

export function roundToNearestMultipleOfGridSize(x, y) {
  const roundedX = Math.round(x / gridPixelSize) * gridPixelSize;
  const roundedY = Math.round(y / gridPixelSize) * gridPixelSize;
  return { roundedX, roundedY };
}

export const calculateImageWidthAndHeight = (actualWidth: number, actualHeight: number) => {
  if (actualWidth <= maxImageWidthAllowedInPx && actualHeight <= maxImageHeightAllowedInPx) {
    return { calculatedWidth: actualWidth, calculatedHeight: actualHeight };
  }

  let calculatedWidth = actualWidth;
  let calculatedHeight = actualHeight;

  if (actualWidth > maxImageWidthAllowedInPx) {
    const aspectRatio = actualHeight / actualWidth;
    calculatedWidth = maxImageWidthAllowedInPx;
    calculatedHeight = calculatedWidth * aspectRatio;
  }

  if (calculatedHeight > maxImageHeightAllowedInPx) {
    const newAspectRatioAfterSettingHeight = calculatedWidth / calculatedHeight;
    calculatedHeight = maxImageHeightAllowedInPx;
    calculatedWidth = calculatedHeight * newAspectRatioAfterSettingHeight;
  }

  return { calculatedWidth, calculatedHeight };
};

export function setupTrackedBlocks(gridBlocks: BlocksContentCollection, signatures) {
  const collection = {};
  Object.values(gridBlocks).forEach((gridBlock) => {
    collection[gridBlock.blockConfig.id] = {
      height: gridBlock.blockConfig.height,
      width: gridBlock.blockConfig.width,
      x: gridBlock.blockConfig.x,
      y: gridBlock.blockConfig.y,
    };
  });

  signatures?.forEach((signature) => {
    collection[signature.signatureBoxId] = {
      height: signature.properties.dimensions.height,
      width: signature.properties.dimensions.width,
      x: signature.properties.position.x,
      y: signature.properties.position.y,
    };
  });

  return collection;
}

export function calculatePageMaxHeight(gridBlocks: BlocksContentCollection, signatureBlocks?: SignatureBox[], activeBlock?: BlockConfig) {
  const pageMaxHeightBasedOnGridBlocks = getPageMaxHeightBasedOnGridBlocks(gridBlocks, activeBlock);
  const pageMaxHeightBasedOnSignatureBlocks = getSignaturesMaxHeight(signatureBlocks);
  if (pageMaxHeightBasedOnSignatureBlocks > pageMaxHeightBasedOnGridBlocks) {
    return pageMaxHeightBasedOnSignatureBlocks;
  }
  return pageMaxHeightBasedOnGridBlocks;
}

export function getPageMaxHeightBasedOnGridBlocks(gridBlocks: BlocksContentCollection, activeBlock?: BlockConfig) {
  let pageMaxHeight = 0;
  for (const key in gridBlocks) {
    let currentBlockPageHeight: number;

    /* gridBlocks parameter doesn't contain an updated state of a block that is currently being dragged,
    resized or having its content changed. So we rely on activeBlock being passed with the updated state. */
    if (activeBlock && key === activeBlock.id) {
      currentBlockPageHeight = activeBlock.height + activeBlock.y;
    } else {
      currentBlockPageHeight = gridBlocks[key].blockConfig.y + (gridBlocks[key].blockConfig.height || 0);
    }

    if (pageMaxHeight < currentBlockPageHeight) {
      pageMaxHeight = currentBlockPageHeight;
    }
  }
  return pageMaxHeight;
}
