import { Block } from '../GridDndEditor/models/Block.model';
import { addGridBlockWithSocket } from '../grid/reduxStore/saveReducers';
import { GridBlockType } from '../shared/gridBlockType';
import { addGridBlockState } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { useCallback } from 'react';

type GridBlockAddHandlerMethod = (documentId: string, documentContent: string, blockConfig: Block) => void;

export function useGridBlockAddHandler(): [GridBlockAddHandlerMethod] {
  const dispatch = useAppDispatch();
  const socketClient = useSocketClient();
  const { data } = useCurrentUser();
  const userId = data.id;

  const gridBlockAddHandler: GridBlockAddHandlerMethod = useCallback(
    (documentId, documentContent, blockConfig) => {
      dispatch(
        addGridBlockWithSocket({
          userId,
          content: documentContent,
          blockConfig,
          socketClient,
          type: GridBlockType.TEXT,
          callback: (response) => {
            const gridBlockId = JSON.parse(response).content.gridId;
            const updatedConfig = {
              ...blockConfig,
              id: gridBlockId,
            };
            const payload = {
              content: documentContent,
              blockConfig: updatedConfig,
              blockId: gridBlockId,
              blockType: GridBlockType.TEXT,
            };
            dispatch(addGridBlockState(payload));
          },
        })
      );
    },
    [dispatch, socketClient, userId]
  );

  return [gridBlockAddHandler];
}
