import { List } from 'antd';
import { Link } from 'react-router-dom';
import { URLS } from 'utils/urls';
import './styles.less';
import usePreviewUrl from '../../../editor/usePreviewUrl';
import { ReactNode, useEffect, useState } from 'react';
import { Document } from 'services/documents/entities/Document';
import { NotifyObject, listUndoObserver } from '../listUndoObserver';
import { DuplicateDocumentAction } from './listActionItems/DuplicateDocumentAction';
import { TrashDocumentAction } from './listActionItems/TrashDocumentAction';
import { CINDER_URLS } from 'utils/cinder-urls';
import { BetaBadge } from '../beta-badge';
import { Chip, Divider } from '@mui/material';

export type ListType = 'Documents' | 'Trash';

export type Props = {
  type: ListType;
  documentList: Document[];
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  isLoading: boolean;
  listEmpty: ReactNode;
  action: {
    icon: ReactNode;
    message: string;
    tooltipText: string;
    onAction: (documentId: string) => Promise<void>;
    onUndo: (documentId: string) => Promise<void>;
    onDuplicate?: () => Promise<void>;
  };
};
export function PipelineList({ type, documentList, setDocumentList, listEmpty, isLoading, action }: Props) {
  const [highlightItemId, setHighlightItemId] = useState('');
  const previewUrlPrefix = usePreviewUrl();

  useEffect(() => {
    const observerId = listUndoObserver.subscribe({
      action: removeDocumentWhenUndoFromAnotherTab,
    });

    return () => {
      listUndoObserver.unsubscribe(observerId);
    };
  }, []);

  const removeDocumentWhenUndoFromAnotherTab = ({ listType, documentId }: NotifyObject) => {
    if (listType !== type) {
      setDocumentList((previousList) => previousList.filter((document) => document.id !== documentId));
    }
  };

  const handleOnListMouseEnter = () => {
    if (!highlightItemId) {
      return;
    }
    setHighlightItemId('');
  };

  const scrollToNewlyDuplicatedDocument = (itemId: string) => {
    const targetDocument = document.getElementById(itemId);
    if (targetDocument) {
      targetDocument.scrollIntoView();
    }
  };

  return (
    <List
      className="pipeline__list"
      loading={isLoading}
      dataSource={documentList}
      locale={{ emptyText: listEmpty }}
      renderItem={(item) => {
        const isEditorv3 = item.editorVersion === 3;
        let assignedUser = '';
        if (item.assigneeUserFirstName && item.assigneeUserLastName) {
          assignedUser = item.assigneeUserFirstName + ' ' + item.assigneeUserLastName;
        }

        const assignedStandaloneContactFullName =
          item.prospectFirstName && item.prospectLastName && `${item.prospectFirstName} ${item.prospectLastName}`;

        const assignedProspect = item.companyName || assignedStandaloneContactFullName || '-';

        return (
          <Link
            to={isEditorv3 ? (item.isLocked ? previewUrlPrefix + item.id : URLS.editDocument(item.id)) : CINDER_URLS.viewSnapshot(item.id)}
            key={item.id}
            id={item.id}
            data-testid={`pipelineItem${item.id}`}
            className={`document-version-${item.editorVersion}`}
          >
            <List.Item
              className={`${item.status.toLocaleLowerCase()} ${highlightItemId === item.id ? ' item-highlight' : ''}`}
              onMouseEnter={handleOnListMouseEnter}
            >
              <List.Item.Meta
                title={
                  <div className="document-title-container">
                    {isEditorv3 ? <BetaBadge /> : null}
                    <div className="document-title">{item.title}</div>
                  </div>
                }
                description={assignedProspect}
              ></List.Item.Meta>
              <div className="assigned_user">{assignedUser}</div>
              <Chip label={item.status} variant="filled" className={`document-status-chip ${item.status.toLowerCase()}`} size="small" />

              <div className="document-actions">
                {type === 'Documents' && isEditorv3 ? (
                  <DuplicateDocumentAction
                    documentForDuplicate={item}
                    setDocumentList={setDocumentList}
                    onDuplicate={action.onDuplicate}
                    setHighlightItemId={setHighlightItemId}
                    scrollToNewlyDuplicatedDocument={scrollToNewlyDuplicatedDocument}
                  />
                ) : null}
                {isEditorv3 ? (
                  <TrashDocumentAction
                    listType={type}
                    documentList={documentList}
                    documentToTrash={item}
                    setDocumentList={setDocumentList}
                    action={action}
                    isLoading={isLoading}
                  />
                ) : null}
              </div>
            </List.Item>
            <Divider className="document-divider" />
          </Link>
        );
      }}
    />
  );
}
