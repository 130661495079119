import { useState, useEffect } from 'react';
import { Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon';
import { IconNamesType } from 'components/icon/icons';
import EditorToolbar from '../toolbar/toolbar';
import { DocumentSettings } from './document-settings';
import { Sections } from './sections';
import { Signatures } from './Signatures';
import { Variables } from './Variables';
import { Design } from './design';
import { Salesforce } from './salesforce';
import './styles.less';
import { EvaluateFeatureFlags } from 'routing/EvaluateFeatureFlags';
import { FeatureFlags } from 'utils/featureFlags';
import { Heading } from 'components/typography';
import { EditorRefType } from '../../../hooks/useEditorRef';

interface Props {
  editorRef: EditorRefType;
  documentId: string;
}

export function EditorSidePanel({ editorRef, documentId }: Props) {
  const { t } = useTranslation();
  const navItems: { key: string; label: string; icon: IconNamesType; children?: React.ReactNode }[] = [
    {
      key: 'document_tab',
      label: t('editor.nav_tab.document'),
      icon: 'IcoSettingsLibrary',
      children: (
        <EvaluateFeatureFlags
          flags={[FeatureFlags.documentSettings]}
          onConditionFailed={() => (
            <>
              <Heading level={5}>Document Settings</Heading>
              <div style={{ marginTop: 16 }}>Coming soon...</div>
            </>
          )}
        >
          <DocumentSettings documentId={documentId} />
        </EvaluateFeatureFlags>
      ),
    },
    { key: 'content_tab', label: t('editor.nav_tab.content'), icon: 'IcoAddShape', children: <EditorToolbar editorRef={editorRef} /> },
    {
      key: 'signatures_tab',
      label: t('editor.nav_tab.signatures'),
      icon: 'IcoSignaturePen',
      children: <Signatures />,
    },
    { key: 'sections_tab', label: t('editor.nav_tab.sections'), icon: 'IcoSectionLibrary', children: <Sections /> },
    {
      key: 'variables_tab',
      label: t('editor.nav_tab.variables'),
      icon: 'IcoVariables',
      children: <Variables documentId={documentId} />,
    },
    { key: 'design_tab', label: t('editor.nav_tab.design'), icon: 'IcoPaintBucket', children: <Design /> },
    { key: 'salesforce_tab', label: t('editor.nav_tab.salesforce'), icon: 'IcoCloudUpload', children: <Salesforce /> },
  ];

  return (
    <Tabs
      className="editor-sidepanel-nav"
      tabPosition="left"
      defaultActiveKey="content"
      items={navItems.map(({ key, label, icon, children }) => ({
        label: <NavItem id={key} label={label} icon={icon} />,
        key: key,
        children: children,
      }))}
    />
  );
}

function NavItem({ id, label, icon }: { id: string; label: string; icon: IconNamesType }) {
  const [isOpenTab, setIsOpenTab] = useState(false);

  const handleMouseEnter = () => {
    setIsOpenTab(true);
  };

  const handleMouseLeave = () => {
    setIsOpenTab(false);
  };

  useEffect(() => {
    const hideTooltip = () => {
      setIsOpenTab(false);
    };
    window.addEventListener('scroll', hideTooltip);
    return () => {
      window.removeEventListener('scroll', hideTooltip);
    };
  }, []);

  return (
    <Tooltip placement="left" title={label} open={isOpenTab}>
      <span id={id} className="editor-sidepanel-nav__icon-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Icon name={icon} />
      </span>
    </Tooltip>
  );
}
