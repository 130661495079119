import React, { useRef, useState } from 'react';
import TextBlockButton from './textBlockButton';
import { Heading } from 'components';
import { Row, Col } from 'antd';
import './styles.less';
import ImageBlockButton from './ImageBlockButton';
import IcoImagePlus from '../../icon/icons/IcoImagePlus';
import ImageLibrary from '../ImageLibrary/ImageLibrary';
import { EditorRefType } from '../../../hooks/useEditorRef';

interface EditorToolbarProps {
  editorRef: EditorRefType;
}

const EditorToolbar: React.FC<EditorToolbarProps> = () => {
  const [isImageButtonClicked, setIsImageButtonClicked] = useState<boolean>(false);
  const imageBlockRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Heading level={5}>Content</Heading>

      <div className="blocks">
        <div data-testid="field_label" className="field_label">
          Blocks
        </div>

        <Row gutter={8}>
          <Col span={12} onClick={() => setIsImageButtonClicked(false)}>
            <TextBlockButton title="Text" />
          </Col>
          <Col data-testid="image-block-button" ref={imageBlockRef} span={12} onClick={() => setIsImageButtonClicked(true)}>
            <ImageBlockButton title="Image" />
          </Col>
        </Row>
      </div>
      {isImageButtonClicked ? (
        <>
          <div className="editor__toolbar__helper">
            <div className="editor__toolbar__helper__icon-div">
              <IcoImagePlus className="editor__toolbar__helper__image-plus-button" />
            </div>

            <div className="editor__toolbar__helper__image-title">Add an Image</div>
            <div className="editor__toolbar__helper__image-helper-desc">
              Drag and drop an image from your desktop or file manager to add an image.
            </div>
          </div>
          <ImageLibrary />
        </>
      ) : null}
    </>
  );
};

export default EditorToolbar;
EditorToolbar.displayName = 'EditorToolbar';
