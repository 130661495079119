import React from 'react';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';
import { Paragraph, Heading } from 'components/typography';
import { dimensions as defaultDimensions, position as defaultPositions } from '../../signatures/constants';
import './styles.less';
import { Dimensions, Position } from '../../shared/interfaces';
import { Tooltip } from 'antd';
import { SignatureEvent } from 'services/repositories/interfaces/SignatureRepository';
import { BlockConfig } from '../models/BlockConfig.model';

interface SignedSignatureProps {
  signee: SignatureEvent;
  signatureId: string;
  isSelected?: boolean;
  position: Position;
  dimensions?: Dimensions;
  signedDate: string;
  bounds?: string;
  onDrag?: (e, block: BlockConfig) => void;
  onSignatureStop?: (e, data) => void;
  onDragStart?: (e, data) => void;
}

const SignedSignature: React.FunctionComponent<SignedSignatureProps> = ({
  signee,
  signatureId,
  position = defaultPositions,
  dimensions = defaultDimensions,
  signedDate,
  bounds = '.fr-box.fr-basic',
  onDrag,
  onSignatureStop,
  onDragStart,
}) => {
  const { t } = useTranslation();

  const { firstName, lastName, ipAddress } = signee;
  const fullName = firstName + ' ' + lastName;
  const formattedSignedDate = new Date(signedDate || '').toLocaleString('en-US', { timeZoneName: 'short' });
  const tooltip = `Signed on ${formattedSignedDate} by ${fullName} @ ${ipAddress}`;
  const signedSignatureDimensions = { height: dimensions.height, width: 'auto' };

  const onSignatureDrag = (e, data) => {
    const { x, y } = data;

    return onDrag?.(e, {
      height: dimensions.height,
      id: signatureId,
      width: dimensions.width,
      x,
      y,
    } as BlockConfig);
  };

  const onSignatureDragStart = (e, data) => {
    return onDragStart?.(e, {
      height: dimensions.height,
      width: dimensions.width,
      id: signatureId,
      x: data.x,
      y: data.y,
    } as BlockConfig);
  };

  const onSignatureDragStop = (e, data) => {
    return onSignatureStop?.(e, {
      height: dimensions.height,
      width: dimensions.width,
      id: signatureId,
      x: data.x,
      y: data.y,
    } as BlockConfig);
  };

  return (
    <Rnd
      className="signed_signature"
      bounds={bounds}
      default={{
        ...position,
        ...signedSignatureDimensions,
      }}
      lockAspectRatio
      minHeight={defaultDimensions.height}
      enableResizing={false}
      disableDragging={true}
      onDrag={onSignatureDrag}
      onDragStop={onSignatureDragStop}
      onDragStart={onSignatureDragStart}
    >
      <Tooltip title={tooltip} placement="bottom">
        <div className="signature signed-signature" data-signature-id={signatureId}>
          <span className="signature-icon-container"></span>
          <div className="signature-signee-container">
            <Paragraph size="xs">{t('editor.signature.signed_by')}</Paragraph>
            <Heading level={1}>{fullName}</Heading>
            <Paragraph size="xs">{formattedSignedDate}</Paragraph>
          </div>
        </div>
      </Tooltip>
    </Rnd>
  );
};

export default SignedSignature;
